import {ROUTER_QUERY_KEY} from 'modules/router/constants';
import {ROUTER_QUERY_TAG_OR_COLLECTION_KEY} from 'plugins/modules/filter/constants';
import {IShopPage} from 'routing/types';

export const EXCEPTION_PATHS = ['/catalog/category', '/catalog'];

export const CART_PAGE = 'cart';
export const BESTSELLER_PAGE = 'bestseller';
export const NEW_PAGE = 'new';
export const POPULAR_PAGE = 'popular';
export const INFO_PAGE = 'info';
export const CHECKOUT_PAGE = 'checkout';

export const CATALOG_PAGE = 'catalog';
export const SEO_CATALOG_PAGE = 'seo';
export const PRODUCT_PAGE = 'product';
export const SEARCH_PAGE = 'search';
export const SERVICE_PAGE = 'services';
export const COLLECTIONS_LIST_PAGE = 'collections';
export const DASHBOARD_PAGE = 'dashboard';
export const BRAND_PAGE = 'brand';
export const BRAND_LIST_PAGE = 'brands';
export const SHOP_LIST_PAGE = 'shops';
export const PROMO_PAGE = 'promotion';
export const FAVOURITES_PAGE = 'favourites';
export const COMPARE_PRODUCTS_PAGE = 'compare';
export const FRANCHISE_PAGE = 'franchise';

export const HOME_ROUTE = '/';
export const CATALOG_ROUTE = `${HOME_ROUTE}${CATALOG_PAGE}`;

export const COLLECTION_ROUTE = `${HOME_ROUTE}${COLLECTIONS_LIST_PAGE}`;
export const CART_ROUTE = `${HOME_ROUTE}${CART_PAGE}`;
export const CHECKOUT_ROUTE = `${HOME_ROUTE}${CHECKOUT_PAGE}`;
export const ONLINE_PAYMENT_ROUTE = `${HOME_ROUTE}online-payment`;
export const THANKS_FOR_YOUR_ORDER_ROUTE = `${HOME_ROUTE}order-status`;
export const SEO_CATALOG_ROUTE = `${CATALOG_ROUTE}/${SEO_CATALOG_PAGE}`;
export const SEARCH_ROUTE = `${HOME_ROUTE}${SEARCH_PAGE}`;
export const PRODUCT_ROUTE = `${HOME_ROUTE}${PRODUCT_PAGE}`;
export const SERVICE_ROUTE = `${HOME_ROUTE}${SERVICE_PAGE}`;
export const BRAND_ROUTE = `${HOME_ROUTE}${BRAND_LIST_PAGE}`;
export const BRAND_LIST_ROUTE = `${HOME_ROUTE}${BRAND_LIST_PAGE}`;
export const SHOP_LIST_ROUTE = `${HOME_ROUTE}${SHOP_LIST_PAGE}`;

export const PROMO_ROUTE = `${HOME_ROUTE}${PROMO_PAGE}`;

export const DASHBOARD_ROOT_ROUTE = `${HOME_ROUTE}${DASHBOARD_PAGE}`;
export const PROFILE_ROOT_ROUTE = `${DASHBOARD_ROOT_ROUTE}/profile`;
export const PROFILE_ORDERS_ROUTE = `${DASHBOARD_ROOT_ROUTE}/orders`;
export const PROFILE_ADDRESS_ROUTE = `${DASHBOARD_ROOT_ROUTE}/addresses`;

export const LOGOUT_ROUTE = `${HOME_ROUTE}logout`;
export const SYSTEM_CATALOG_PAGE_ROUTE = `${CATALOG_ROUTE}/[${ROUTER_QUERY_KEY}]`;
export const BESTSELLER_CATALOG_PAGE_ROUTE = `${CATALOG_ROUTE}/${BESTSELLER_PAGE}`;
export const NEW_CATALOG_PAGE_ROUTE = `${CATALOG_ROUTE}/${NEW_PAGE}`;
export const POPULAR_CATALOG_PAGE_ROUTE = `${CATALOG_ROUTE}/${POPULAR_PAGE}`;
export const SYSTEM_CATALOG_TAG_PAGE_ROUTE = `${SYSTEM_CATALOG_PAGE_ROUTE}/[${ROUTER_QUERY_TAG_OR_COLLECTION_KEY}]`;
export const SYSTEM_CATALOG_COLLECTIONS_PAGE = `${SYSTEM_CATALOG_PAGE_ROUTE}/${COLLECTIONS_LIST_PAGE}`;
export const FAVOURITES_ROUTE = `${HOME_ROUTE}${FAVOURITES_PAGE}`;
export const FRANCHISE_ROUTE = `${HOME_ROUTE}${FRANCHISE_PAGE}`;

export const COMPARE_PRODUCTS_ROUTE = `${HOME_ROUTE}${COMPARE_PRODUCTS_PAGE}`;
export const INFO_ROUTE = `${HOME_ROUTE}${INFO_PAGE}`;

// TODO: При разрастании поменять на хеш-таблицу, в которой по ключу path будут лежать объекты
export const PAGES: {[key: string]: IShopPage} = {
    BESTSELLER_PAGE: {
        path: BESTSELLER_PAGE,
        title: 'Хиты продаж',
        url: BESTSELLER_CATALOG_PAGE_ROUTE,
    },
    BRAND_LIST_PAGE: {
        path: BRAND_LIST_ROUTE,
        title: 'Бренды',
    },
    CART_PAGE: {
        path: CART_PAGE,
        title: 'Корзина',
        url: CART_PAGE,
    },
    CATALOG_PAGE: {
        path: CATALOG_ROUTE,
        title: 'Каталог',
    },
    COLLECTIONS_PAGE: {
        path: COLLECTION_ROUTE,
        title: 'Коллекции',
    },
    COMPARE_PRODUCTS_PAGE: {
        path: COMPARE_PRODUCTS_PAGE,
        title: 'Сравнить товары',
        url: COMPARE_PRODUCTS_ROUTE,
    },
    FAVOURITES_PAGE: {
        path: FAVOURITES_PAGE,
        title: 'Избранное',
        url: FAVOURITES_ROUTE,
    },
    FRANCHISE_PAGE: {
        path: FRANCHISE_PAGE,
        title: 'Франшиза',
        url: FRANCHISE_ROUTE,
    },
    HOME_PAGE: {
        path: HOME_ROUTE,
        title: 'Главная',
    },
    NEW_PAGE: {
        path: NEW_PAGE,
        title: 'Для новых кулинарных свершений',
        url: NEW_CATALOG_PAGE_ROUTE,
    },
    POPULAR_PAGE: {
        path: POPULAR_PAGE,
        title: 'Популярное',
        url: POPULAR_CATALOG_PAGE_ROUTE,
    },
    PROFILE_ADDRESS_PAGE: {
        path: PROFILE_ADDRESS_ROUTE,
        title: 'Мои адреса',
    },
    PROFILE_ORDERS: {
        path: PROFILE_ORDERS_ROUTE,
        title: 'Мои заказы',
    },
    PROFILE_PAGE: {
        path: PROFILE_ROOT_ROUTE,
        title: 'Мой профиль',
    },
    PROMO_LIST_PAGE: {
        path: PROMO_ROUTE,
        title: 'Акции',
    },
    SEARCH_PAGE: {
        path: SEARCH_PAGE,
        title: 'Поиск',
        url: SEARCH_ROUTE,
    },
    SEO_CATALOG_PAGE: {
        path: SEO_CATALOG_PAGE,
        title: 'Каталог товаров',
        url: SEO_CATALOG_ROUTE,
    },
    SERVICE_PAGE: {
        path: SERVICE_PAGE,
        title: 'Сервисы и услуги',
        url: SERVICE_ROUTE,
    },
    SHOP_LIST_PAGE: {
        path: SHOP_LIST_ROUTE,
        title: 'Магазины',
    },
};

export const PRODUCT_PATH = `${PRODUCT_ROUTE}/[path]`;
export const CATALOG_PATH = `${CATALOG_ROUTE}/[path]`;
export const COLLECTION_X_PATH = `${COLLECTION_ROUTE}/[path]`;
export const PROFILE_ORDER_PATH = `${PROFILE_ORDERS_ROUTE}/[id]`;
