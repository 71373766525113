import {BACK_BUTTON_URL_TYPE} from 'components/BackButton';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {OnlyDesktop} from 'components/OnlyDesktop';
import {CategoryChildSlider} from 'modules/categories/components/CategoryChildSlider';
import {CategoryPageHeader} from 'modules/categories/components/CategoryPageHeader';
import {ProductListPageContext, ProductListPageFunctionalityContext} from 'modules/products/context';
import {useHeaderBannerOfListing} from 'modules/products/hooks/useHeaderBannerOfListing';
import {useListingTitle} from 'modules/products/hooks/useListingTitle';
import {ComboFiltersTagList} from 'plugins/modules/filter/components/ComboFiltersTagList';
import {FilterContext} from 'plugins/modules/filter/context';
import React, {FC, useContext} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

interface IProps {
    withBanners?: boolean;
}

export const ProductListDesktopHeader: FC<IProps> = ({withBanners = true}) => {
    const {category} = useContext(ProductListPageContext);
    const {comboFilterList} = useContext(FilterContext);
    const functionality = useContext(ProductListPageFunctionalityContext);

    const title = useListingTitle(category);

    // TODO: Temporary commented
    // const renderCollectionSwitcher = useCollectionSwitcher(category);
    // const renderAdvicesServices = useAdvicesServices(category);
    const renderBanner = useHeaderBannerOfListing({
        category,
        className: columnStyle,
        withBanners,
    });

    return (
        <OnlyDesktop isContainer>
            <Grid className={style.root} noRowGap>
                {functionality.title && title && (
                    <CategoryPageHeader className={columnStyle} title={title} urlType={BACK_BUTTON_URL_TYPE.new} />
                )}
                {renderBanner}
                {Boolean(comboFilterList.length) && <ComboFiltersTagList className={columnStyle} />}
                {category && Boolean(category?.childrenCategory.length) && (
                    <CategoryChildSlider
                        categoryNode={category}
                        className={columnStyle}
                        withHidingInactiveNavBtn
                        withSidesGradient
                    />
                )}
            </Grid>
        </OnlyDesktop>
    );
};
