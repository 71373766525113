import classNames from 'classnames';
import {ShadowBlock} from 'components/ShadowBlock';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import {ModalOverlay} from 'modules/modals/components/ModalOverlay';
import {PortalContext} from 'modules/portal/context/PortalContext';
import closeIcon from 'public/icons/close.svg';
import React, {FC, MouseEventHandler, ReactNode, useContext, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    hasOverlay?: boolean;
    hasClose?: boolean;
    adaptive?: boolean;
    animated?: boolean;
    overlayClassName?: string;
    onClose?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
    overlay?: ReactNode;
}

export const RegularModal: FC<IProps> = ({
    hasOverlay = true,
    children,
    animated,
    adaptive,
    hasClose,
    className,
    overlayClassName,
    overlay = <ModalOverlay data-modal-action="close" />,
    onClose,
}) => {
    const {visibility} = useContext(PortalContext);

    const renderClassList = useMemo<Record<string, boolean>>(
        () => ({
            [style.regularModalIsVisibleAnimated]: Boolean(visibility && animated),
            [style.regularModalIsHiddenAnimated]: Boolean(!visibility && animated),
            [style.regularModalIsVisible]: Boolean(visibility && !animated),
            [style.regularModalIsHidden]: Boolean(!visibility && !animated),
        }),
        [visibility, animated]
    );

    return (
        <div className={classNames(style.regularModal, renderClassList, overlayClassName)}>
            <div className={classNames(style.regularModalContent, className, {[style.regularModalAdaptive]: adaptive})}>
                {hasClose && (
                    <button
                        className={classNames(style.regularModalClose, 'regular-modal-close')}
                        data-modal-action="close"
                        onClick={onClose}
                    >
                        <SvgIcon svg={closeIcon} />
                    </button>
                )}
                {children}
            </div>
            <ShadowBlock>{hasOverlay && overlay}</ShadowBlock>
        </div>
    );
};
