import classNames from 'classnames';
import {BackButton} from 'components/BackButton';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {isMobileByScreen} from 'core/helpers';
import {DIYAllCategoriesLink} from 'modules/categories/components/Menu/DIY/DIYAllCategoriesLink';
import {DIYCatalogMenuListItem} from 'modules/categories/components/Menu/DIY/DIYCatalogMenuListItem';
import {DIYChevronIconItem} from 'modules/categories/components/Menu/DIY/DIYChevronIconItem';
import {CatalogMenuSectionLink} from 'modules/categories/components/Menu/GeneralMenu/CatalogMenuSectionLink';
import {PromotionsBlock} from 'modules/categories/components/PromotionsBlock';
import {CATEGORY_LEVEL} from 'modules/categories/constants';
import {useDIYCatalogMenuList} from 'modules/categories/hooks/useDIYCatalogMenuList';
import {CategoryBanners} from 'modules/common/components/CategoryBanners';
import {getMenuItemUrl} from 'modules/menu/helpers';
import {IHeaderCatalogMenuItem, THeaderCatalogMenu} from 'modules/menu/types';
import {Menu} from 'new-models';
import Link from 'next/link';
import React, {CSSProperties, memo, ReactNode, useEffect, useMemo, useState} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();
const menuColumnStyle = getGridColumnStyles({md: 3});
const contentColumnStyle = getGridColumnStyles({md: 9});

interface IProps {
    parentCategory?: IHeaderCatalogMenuItem;
    categoriesList: THeaderCatalogMenu;
    depth?: number;
    catalogPromoList?: Menu;
}

export const DIYCatalogMenuList = memo<IProps>(function DIYCatalogMenuList({
    categoriesList,
    depth = CATEGORY_LEVEL.first,
    parentCategory,
    catalogPromoList,
}) {
    const [catalogMenu, setCatalogMenu] = useState(categoriesList);
    const [parentItem, setParentItem] = useState(parentCategory || null);

    const {
        activeCategory,
        activeCategoryId,
        bannersNotShowing,
        handleLinkClick,
        handleMouseLeave,
        parentCategoryLink,
        renderInteractionCallback,
        setActiveCategoryId,
        handleBackClick,
    } = useDIYCatalogMenuList({
        categoriesList: catalogMenu,
        depth,
        parentCategory: parentItem,
        setCatalogMenu,
        setParentItem,
    });

    const renderMobileCategoryHeader = useMemo<ReactNode>(() => {
        return (
            <Grid className={classNames(columnStyle, style.catalogMenuHeader)}>
                {parentItem ? (
                    <>
                        <BackButton className={style.catalogMenuHeaderBack} onClick={handleBackClick} />
                        <Link href={parentCategoryLink}>
                            <a className={columnStyle} onClick={handleLinkClick}>
                                <Typography className={style.catalogMenuSectionItemsText} element="h2">
                                    {parentItem.title}
                                </Typography>
                            </a>
                        </Link>
                    </>
                ) : (
                    <Typography
                        className={classNames(style.catalogMenuSectionItemsText, columnStyle)}
                        element="h2"
                        variant="h2"
                    >
                        Каталог товаров
                    </Typography>
                )}
            </Grid>
        );
    }, [parentItem, handleBackClick, parentCategoryLink, handleLinkClick]);

    const depthStyle = useMemo<CSSProperties>(() => {
        return {
            zIndex: depth,
        };
    }, [depth]);

    useEffect(() => {
        if (isMobileByScreen()) {
            return;
        }

        const firstCategory = catalogMenu[0];

        if (!firstCategory) {
            return;
        }

        setActiveCategoryId(firstCategory.id);
    }, [catalogMenu, setActiveCategoryId]);

    return (
        <Grid className={style.grid}>
            <div className={classNames(style.menuColumn, menuColumnStyle)} style={depthStyle}>
                <Grid className={style.mainContent} gridColumnsCount={3} noRowGap>
                    {renderMobileCategoryHeader}
                    <ul className={classNames(style.catalogMenuSection, columnStyle)}>
                        <>
                            <DIYAllCategoriesLink className={style.catalogMenuSectionItems} parentItem={parentItem} />
                            {catalogMenu.map((categoryNode) => {
                                if (!categoryNode.title) {
                                    return null;
                                }

                                return (
                                    <li
                                        className={classNames(style.catalogMenuSectionItems, {
                                            [style.catalogMenuSectionItemsActive]: categoryNode.id === activeCategoryId,
                                        })}
                                        key={categoryNode.id}
                                        {...renderInteractionCallback(categoryNode)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <CatalogMenuSectionLink
                                            categoryNode={categoryNode}
                                            className={style.catalogMenuSectionItemsName}
                                            href={getMenuItemUrl(categoryNode.url)}
                                        >
                                            <div>
                                                {categoryNode.icon && (
                                                    <div
                                                        className={style.catalogMenuSectionItemsBadge}
                                                        style={{backgroundColor: categoryNode.titleColor}}
                                                    >
                                                        <MediaHarvester
                                                            className={style.catalogMenuSectionItemsBadgeIcon}
                                                            media={categoryNode.icon}
                                                        />
                                                        <Typography variant="p-small-strong">
                                                            {categoryNode.icon.title}
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Typography
                                                    className={style.catalogMenuCategoryText}
                                                    element="p"
                                                    style={
                                                        !categoryNode.icon && categoryNode.titleColor
                                                            ? ({color: categoryNode.titleColor} as CSSProperties)
                                                            : undefined
                                                    }
                                                >
                                                    {categoryNode.title}
                                                </Typography>
                                            </div>
                                            <DIYChevronIconItem />
                                        </CatalogMenuSectionLink>
                                    </li>
                                );
                            })}
                        </>
                    </ul>

                    {!parentItem && catalogPromoList?.sections && Boolean(catalogPromoList?.sections.length) && (
                        <PromotionsBlock sections={catalogPromoList.sections} />
                    )}
                </Grid>
                <div
                    className={classNames(columnStyle, style.categoryBanners, style.categoryBannersMobile, {
                        [style.hidden]: bannersNotShowing,
                    })}
                >
                    <CategoryBanners categoryCode={parentItem?.code} />
                </div>
            </div>
            <div className={classNames(style.contentColumn, contentColumnStyle)}>
                {CATEGORY_LEVEL.first === depth &&
                    activeCategory &&
                    Boolean(activeCategory.childrenCategory.length) && (
                        <div className={style.desktop}>
                            <DIYCatalogMenuListItem categoriesList={activeCategory.childrenCategory} />
                        </div>
                    )}
                {activeCategory && Boolean(activeCategory.childrenCategory.length) && (
                    <div className={style.mobile}>
                        <DIYCatalogMenuList
                            categoriesList={activeCategory.childrenCategory}
                            depth={depth + 1}
                            parentCategory={activeCategory}
                        />
                    </div>
                )}
                <div
                    className={classNames(columnStyle, style.categoryBanners, style.categoryBannersDesktop, {
                        [style.hidden]: bannersNotShowing,
                    })}
                >
                    <CategoryBanners categoryCode={activeCategory?.code} />
                </div>
            </div>
        </Grid>
    );
});
