import {IHeaderCatalogMenuItem, TFooterMenu, THeaderCatalogMenu} from 'modules/menu/types';
import {Link, Menu, MenuSection} from 'new-models';

export const getMenuByCode = (menuList: Menu[], code: string): Menu | undefined => {
    return menuList.find(({place}) => place === code);
};

export const getMenuItemUrl = (url?: Link): string => {
    return url?.value ?? '';
};

interface INodeMap {
    [key: string]: IHeaderCatalogMenuItem;
}

export const makeHeaderCatalogMenu = (menu?: Menu): THeaderCatalogMenu => {
    if (!menu) {
        return [];
    }

    const tree: THeaderCatalogMenu = [];
    const nodeMap: INodeMap = {};

    menu.sections.forEach((item: MenuSection) => {
        nodeMap[item.id] = {
            ...item,
            childrenCategory: [],
        };
    });

    Object.values(nodeMap).forEach((node) => {
        const {parentId} = node;

        if (parentId && nodeMap[parentId]) {
            nodeMap[parentId].childrenCategory.push(node);
        } else {
            tree.push(node);
        }
    });

    return tree;
};

export const getFooterMenu = (menu?: Menu): TFooterMenu => {
    if (!menu) {
        return [];
    }

    const parentList = menu.sections.filter(({parentId}) => !parentId);

    return parentList.map((item) => {
        return {
            data: item,
            list: menu.sections.filter(({parentId}) => parentId === item.id),
        };
    });
};
