import {ProductOfferDetailResponse} from 'models';
import {TGalleryMode} from 'modules/slider/types';
import {DeliveryInfo, MediaGroupVisual, ProductXDataResponse} from 'new-models';
import {createContext} from 'react';

interface IProductPageContextProps {
    galleryMode: TGalleryMode;
    isProductLoading?: boolean;
    isProductAvailable?: boolean;
    productImages: MediaGroupVisual[];
    // TODO: for the time of mixing two mock servers
    productData: (Omit<ProductOfferDetailResponse, 'product'> & ProductXDataResponse) | null;
    productDeliveryInfo?: DeliveryInfo[];
}

export const ProductPageContext = createContext<IProductPageContextProps>({} as IProductPageContextProps);
