import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TResponse} from 'core/types';
import {actionSetBannerList, actionSetFeedbackSubjects} from 'modules/common/slice';
import {
    FeedbackCreateDataRequest,
    FeedbackSubjectsDataResponse,
    PromosDataResponse,
    SubscribeDataRequest,
} from 'new-models';

export const getBannerList: TAppThunk<void> = async (dispatch, _, {commonService}) => {
    const data = await dispatch(
        requestAction<TResponse<PromosDataResponse>>({
            requestCb: () => commonService.getPromoList(),
            type: actionSetBannerList.type,
        })
    );

    dispatch(actionSetBannerList(data.promos));
};

export const emailSubscribe =
    (values: SubscribeDataRequest): TAppThunk<void> =>
    async (dispatch, _, {commonService}) => {
        await dispatch(
            requestAction<TResponse<undefined>>({
                requestCb: () => commonService.emailSubscribe(values),
            })
        );
    };

export const getFeedbackSubjects: TAppThunk<void> = async (dispatch, _, {commonService}) => {
    const data = await dispatch(
        requestAction<TResponse<FeedbackSubjectsDataResponse>>({
            requestCb: () => commonService.getFeedbackSubjects(),
            type: actionSetFeedbackSubjects.type,
        })
    );

    dispatch(actionSetFeedbackSubjects(data.subjects));
};

export const handleFeedback =
    (values: FeedbackCreateDataRequest): TAppThunk<void> =>
    async (dispatch, _, {commonService}) => {
        await dispatch(
            requestAction<TResponse<undefined>>({
                requestCb: () => commonService.handleFeedback(values),
            })
        );
    };
