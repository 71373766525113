import {IOptionsMediaHarvesterProps} from 'components/MediaHarvester';
import {getRuntimeConfig} from 'core/next/helpers';
import {MediaImage, MediaImageAdaptive, MediaTypeEnum} from 'new-models';

const RESIZE_HOST = getRuntimeConfig().NEXT_PUBLIC_RESIZE_IMAGE_HOST;
const MAIN_HOST = getRuntimeConfig().NEXT_PUBLIC_BACKEND_HOST;
const URL_PREFIX = 'images';
const IMAGE_FORMAT = 'webp';

export const IMAGE_RESIZE_CODE = {
    original: 'original',
    w108: 'w108',
    w200: 'w200',
    w220: 'w220',
    w400: 'w400',
    w410: 'w410',
    w564: 'w564',
    w656: 'w656',
    w768: 'w768',
    w1176: 'w1176',
    w1584: 'w1584',
    w1920: 'w1920',
} as const;

export type TImageResizeCode = (typeof IMAGE_RESIZE_CODE)[keyof typeof IMAGE_RESIZE_CODE];

export interface IImageAdaptiveResizeCode {
    urlLCode?: TImageResizeCode;
    urlMCode?: TImageResizeCode;
    urlMobileCode?: TImageResizeCode;
    urlSCode?: TImageResizeCode;
}

export const removeUrlHost = (url: string): string => {
    let urlWithoutHost = url;

    try {
        const urlObj = new URL(url);
        urlWithoutHost = urlObj.pathname + urlObj.search + urlObj.hash;
    } catch (e) {
        console.error(e);
    }

    if (urlWithoutHost.startsWith('/')) {
        return urlWithoutHost.slice(1);
    }

    return urlWithoutHost;
};

const replaceLastDot = (url: string): string => {
    const lastDotIndex = url.lastIndexOf('.');

    return url.substring(0, lastDotIndex) + '-' + url.substring(lastDotIndex + 1);
};

export const getResizedImageUrlByCode = (url: string, code?: TImageResizeCode): string => {
    if (!url) {
        return url;
    }

    if (!RESIZE_HOST || !code || url.endsWith('.svg')) {
        try {
            new URL(url);
            return url;
        } catch {
            return MAIN_HOST + url;
        }
    }

    const formattedUrl = replaceLastDot(url);

    return `${RESIZE_HOST}/${URL_PREFIX}/${code}${formattedUrl}.${IMAGE_FORMAT}`;
};

export const getResizedImageUrl = (media: MediaImage, urlResizeCode?: TImageResizeCode): MediaImage => {
    if ('image' !== media.type || !media.url) {
        return media;
    }

    return {
        ...media,
        url: getResizedImageUrlByCode(media.url, urlResizeCode),
    };
};

export const getResizedImageAdaptiveUrl = (
    media: MediaImageAdaptive,
    codeData: IImageAdaptiveResizeCode = {}
): MediaImageAdaptive => {
    if (!('urlMobile' in media)) {
        return media;
    }

    const {urlL, urlM, urlMobile, urlS} = media;
    const {urlLCode, urlMCode, urlMobileCode, urlSCode} = codeData;

    return {
        ...media,
        urlL: getResizedImageUrlByCode(urlL, urlLCode),
        urlM: getResizedImageUrlByCode(urlM, urlMCode),
        urlMobile: getResizedImageUrlByCode(urlMobile, urlMobileCode),
        urlS: getResizedImageUrlByCode(urlS, urlSCode),
    };
};

/* eslint-disable sort-keys */
export const IMAGE_ADAPTIVE_RESIZE_MAP = {
    homePageMainBanner: {urlLCode: IMAGE_RESIZE_CODE.w1584, urlMobileCode: IMAGE_RESIZE_CODE.w656},
    homePageSecondaryBanner: {urlLCode: IMAGE_RESIZE_CODE.w768, urlMobileCode: IMAGE_RESIZE_CODE.w656},
    homePageCollection: {urlLCode: IMAGE_RESIZE_CODE.w1584, urlMobileCode: IMAGE_RESIZE_CODE.w656},
    productSliderBanner: {urlLCode: IMAGE_RESIZE_CODE.w1176, urlMobileCode: IMAGE_RESIZE_CODE.w400},
    navigationMainBanner: {urlLCode: IMAGE_RESIZE_CODE.w1176, urlMobileCode: IMAGE_RESIZE_CODE.w656},
    navigationSecondaryBanner: {urlLCode: IMAGE_RESIZE_CODE.w564, urlMobileCode: IMAGE_RESIZE_CODE.w656},
} as const;
/* eslint-enable sort-keys */

export type TImageAdaptiveResizeMapKey = keyof typeof IMAGE_ADAPTIVE_RESIZE_MAP;

export type TImageAdaptiveResizeMap = (typeof IMAGE_ADAPTIVE_RESIZE_MAP)[TImageAdaptiveResizeMapKey];

export const getAdaptiveImageResizeMapCode = (
    resizeKey?: TImageAdaptiveResizeMapKey
): TImageAdaptiveResizeMap | undefined => {
    return resizeKey && resizeKey in IMAGE_ADAPTIVE_RESIZE_MAP ? IMAGE_ADAPTIVE_RESIZE_MAP[resizeKey] : undefined;
};

export type TGenerateMediaOptionsParams = {
    type: MediaTypeEnum;
    resizeMapKey?: TImageAdaptiveResizeMapKey;
    resizeKey?: TImageResizeCode;
};

export const generateMediaOptions = ({
    type,
    resizeMapKey,
    resizeKey,
}: TGenerateMediaOptionsParams): IOptionsMediaHarvesterProps => {
    switch (type) {
        case MediaTypeEnum.ImageAdaptive: {
            return {adaptiveImage: {resizeMapKey}};
        }
        case MediaTypeEnum.Image:
        default: {
            return {imageResizeCode: resizeKey};
        }
    }
};
