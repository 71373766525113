import classNames from 'classnames';
import {Button} from 'components/Button';
import {PrevDefaultLink} from 'components/PrevDefaultLink';
import {Typography} from 'components/Typography';
import {formatPrice} from 'core/helpers';
import {getProductPluralText} from 'core/helpers/plural';
import {useDemoAlert} from 'core/hooks/useDemoAlert';
import {OrderCardItemDeliveryDetails} from 'modules/orders/components/OrderCardItemDeliveryDetails';
import {OrderCardItemHeader} from 'modules/orders/components/OrderCardItemHeader';
import {PaymentStatus} from 'modules/orders/components/PaymentStatus';
import {ShouldPayAlert} from 'modules/orders/components/ShouldPayAlert';
import {OrderPaymentStatusEnum, OrderShort, OrderStatus} from 'new-models';
import React, {memo, useCallback, useMemo} from 'react';
import {PROFILE_ORDERS_ROUTE} from 'routing/constants';

import style from './style.module.scss';

interface IProps extends OrderShort {
    className?: string;
}

export const OrderCardItem = memo<IProps>(function OrderCard(order) {
    const {className, id, date, total, paidStatus, orderStatus, paymentMethod} = order;

    const {handleShowDemoAlert} = useDemoAlert();

    const isShouldPay = useMemo(
        () =>
            orderStatus === OrderStatus.IN_DELIVERY && paidStatus.status === OrderPaymentStatusEnum.StatusEnum.NOTPAID,
        [orderStatus, paidStatus]
    );

    const renderPaymentMethodTitle = useMemo<React.ReactNode>(() => {
        if (!paymentMethod || !paymentMethod.title) {
            return null;
        }

        return (
            <Typography color="gray100" variant="p-strong">
                {paymentMethod.title}
            </Typography>
        );
    }, [paymentMethod]);

    const PaymentStatusComponent = useCallback<FC>(() => {
        if ('on-reciept' === paymentMethod?.code) {
            return null;
        }

        return <PaymentStatus statusType={paidStatus} />;
    }, [paidStatus, paymentMethod?.code]);

    return (
        <div className={classNames(style.root, {[style.isShouldPay]: isShouldPay}, className)} data-skeleton-item>
            {isShouldPay && <ShouldPayAlert />}
            <PrevDefaultLink href={`${PROFILE_ORDERS_ROUTE}/${id}`}>
                <OrderCardItemHeader
                    createDate={date}
                    id={id}
                    orderNumber={id}
                    price={total.totalCost}
                    quantity={order.quantity}
                    status={orderStatus}
                />
                <div className={style.content}>
                    <OrderCardItemDeliveryDetails
                        date={order.date}
                        deliveryData={order.deliveryData}
                        deliveryMethod={order.deliveryMethod}
                    />
                    <div className={style.paymentStatusDesktop}>
                        {renderPaymentMethodTitle}
                        <PaymentStatusComponent />
                    </div>
                    <div className={style.paymentStatusMobile}>
                        <div className={style.paymentStatusInner}>
                            <div className={style.paymentStatusText}>
                                {renderPaymentMethodTitle}
                                <PaymentStatusComponent />
                            </div>
                            <div className={style.paymentStatusPrice}>
                                <Typography color="gray50" variant="p">
                                    {order.quantity} {getProductPluralText(order.quantity)}
                                </Typography>
                                <Typography
                                    className={style.price}
                                    color="primary100"
                                    element="h5"
                                    variant="price-preview-m"
                                >
                                    {formatPrice(String(total.totalCost))} <Typography variant="p">₽</Typography>
                                </Typography>
                            </div>
                        </div>
                        {isShouldPay && (
                            <Button
                                className={style.paymentStatusAction}
                                isStretched
                                onClick={handleShowDemoAlert}
                                size="medium"
                            >
                                Оплатить
                            </Button>
                        )}
                        {/** // TODO: enable this button when it is clear when it is needed */}
                        {/* <Button onClick={open(id)} className={style.paymentStatusAction} theme="secondary">Отменить заказ</Button> */}
                    </div>
                </div>
            </PrevDefaultLink>
        </div>
    );
});
