import {IMainInfoDataValues, TGridLayout, TSliderGrid} from 'modules/product-card/types';

export const PRODUCT_PAGE_GRID_LAYOUT: TGridLayout = {
    contentBlock: 8,
    priceBlock: 4,
};

export const PRODUCT_SLIDER_GRID_LAYOUT: TSliderGrid = {
    gridColumns: PRODUCT_PAGE_GRID_LAYOUT.contentBlock,
    sliderBlock: 7,
    thumbBlock: 1,
};

export const ADVICE_LIST_PRODUCT_CARD = 'productCard';

export const INTERIOR_LABEL: string = 'visual-';

export const MAX_IMAGES_NUMBER: number = 4;

export const VISUAL_MAIN_CARD_IMAGE_MAP = [
    {
        imageSize: {
            height: 640,
            width: 1280,
        },
        screenWidth: 1280,
    },
    {
        imageSize: {
            height: 720,
            width: 1440,
        },
        screenWidth: 1440,
    },
    {
        defaultSize: true,

        imageSize: {
            height: 740,
            width: 1920,
        },
        screenWidth: 1920,
    },
];

// TODO: Вернуться, если пересмотрим использование таких конструкций на проекте (калькуляторы, карточка, модалки)
// Необходимо исключение, чтобы ключи были в текущем порядке
/* eslint-disable sort-keys */
export const MAIN_INFO_DATA: Record<
    'ADVICES' | 'DESCRIPTION' | 'FILES' | 'PROPERTIES' | 'SIMILAR_PRODUCTS',
    IMainInfoDataValues
> = {
    SIMILAR_PRODUCTS: {
        label: 'Аналоги',
        name: 'similar-products',
    },
    DESCRIPTION: {
        label: 'Описание',
        name: 'description',
    },
    PROPERTIES: {
        label: 'Характеристики',
        name: 'properties',
    },
    FILES: {
        label: 'Инструкции и документы',
        name: 'files',
    },
    ADVICES: {
        label: 'Советы',
        name: 'advices',
    },
};
/* eslint-enable sort-keys */

export const NAV_USED_IN = {
    header: 'header',
    main: 'main',
} as const;

export const NAV_TABS_MIN_NUMBER = 2;
export const HEADER_HEIGHT_ADAPTIVE = -118;
export const HEADER_HEIGHT_DESKTOP = -160;

export const NAVIGATION_TABS_TYPE = {
    fixed: 'fixed',
    static: 'static',
} as const;

export const PRODUCT_RATING_ANCHOR_ID = 'anchor-product-rating';
