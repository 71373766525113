import {Button} from 'components/Button';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {TPropsWithClassName} from 'core/types';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {useProfileRemoveAccountModalApi} from 'modules/user/hooks/useProfileRemoveAccountModalApi';
import dynamic from 'next/dynamic';
import React, {memo} from 'react';

const RemoveAccountModal = dynamic(() => import('modules/user/components/RemoveAccountModal'), {
    loading: () => <ModalLazyLoader />,
});

interface IProps extends TPropsWithClassName {}

export const RemoveAccount = memo<IProps>(function RemoveAccount({className}) {
    const {open: openRemoveAccountModal} = useProfileRemoveAccountModalApi();

    return (
        <>
            <div className={className}>
                <Button data-skeleton-item isContentSize isNotAdaptive onClick={openRemoveAccountModal} theme="ghost">
                    Удалить аккаунт
                </Button>
            </div>
            <Modal name={MODALS.PROFILE_REMOVE_ACCOUNT.name} willCloseOnUrlChange willMount>
                <RemoveAccountModal title="Удаление аккаунта" />
            </Modal>
        </>
    );
});
