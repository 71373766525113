import {getGridColumnStyles, Grid} from 'components/Grid';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {OrderXNoPaidSection} from 'modules/orders/components/OrderXNoPaidSection';
import {OrderXPageAboutInfo} from 'modules/orders/components/OrderXPageAboutInfo';
import {OrderXPageDetailsInfo} from 'modules/orders/components/OrderXPageDetailsInfo';
import {OrderXPageHeader} from 'modules/orders/components/OrderXPageHeader';
import {OrderXPageSection} from 'modules/orders/components/OrderXPageSection';
import {OrderXPageContext} from 'modules/orders/context';
import {OrderPaymentStatusEnum} from 'new-models';
import dynamic from 'next/dynamic';
import React, {memo, useContext} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

const OrderCancelModal = dynamic(() => import('modules/orders/components/OrderCancelModal'), {
    loading: () => <ModalLazyLoader />,
});

const OrderShelfLifeModal = dynamic(() => import('modules/orders/components/OrderShelfLifeModal'), {
    loading: () => <ModalLazyLoader />,
});

interface IProps {}

export const OrderXPage = memo<IProps>(function OrderXPage() {
    const {orderData} = useContext(OrderXPageContext);

    const {paymentMethod} = orderData || {};

    const isNotPaid = OrderPaymentStatusEnum.StatusEnum.NOTPAID === orderData?.paidStatus.status;
    const isOnlinePay = 'card-or-sbp' === paymentMethod?.code;

    if (!orderData) {
        return null;
    }

    return (
        <>
            <Grid className={style.root}>
                <OrderXPageHeader className={columnStyle} {...orderData} />

                {isNotPaid && isOnlinePay && <OrderXNoPaidSection className={columnStyle} {...orderData} />}

                <OrderXPageSection className={columnStyle} withBorder>
                    <OrderXPageAboutInfo {...orderData} />
                </OrderXPageSection>

                <OrderXPageSection className={columnStyle} withBorder>
                    <OrderXPageDetailsInfo {...orderData} />
                </OrderXPageSection>
            </Grid>
            <Modal name={MODALS.ORDER_CANCEL.name} willMount>
                <OrderCancelModal />
            </Modal>
            <Modal name={MODALS.ORDER_SHELF_LIFE.name} willMount>
                <OrderShelfLifeModal />
            </Modal>
        </>
    );
});
