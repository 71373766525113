import Script from 'next/script';
import React, {memo} from 'react';

const GoogleAnalytics = memo<{googleAnalyticsId: string}>(function GoogleAnalytics({googleAnalyticsId}) {
    return (
        <>
            <Script
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: `
                        (function(w,d,s,l,i){
                            w[l]=w[l]||[];
                            w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                            var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
                            j.async=true;
                            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                            f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${googleAnalyticsId}');
                    `,
                }}
                id="gtm"
                strategy="afterInteractive"
            />

            <noscript>
                <iframe
                    height="0"
                    src={`https://www.googletagmanager.com/ns.html?id=${googleAnalyticsId}`}
                    style={{display: 'none', visibility: 'hidden'}}
                    width="0"
                ></iframe>
            </noscript>
        </>
    );
});

export default GoogleAnalytics;
