import {TClosureCallback} from 'core/types';
import {MAIN_INFO_DATA} from 'modules/product-card/constants';
import {IUseIsNeedMainInfoItemParams} from 'modules/product-card/types';
import {useCallback} from 'react';

export const useIsNeedMainInfoBlock: TClosureCallback<
    IUseIsNeedMainInfoItemParams,
    TClosureCallback<string, boolean>
> = ({product, advices, isProductAvailable}) => {
    return useCallback(
        (name) => {
            const isNeedAdvices = name === MAIN_INFO_DATA.ADVICES.name && Boolean(advices?.length);
            const isNeedDescription = name === MAIN_INFO_DATA.DESCRIPTION.name && Boolean(product?.description?.length);
            const isNeedFiles = name === MAIN_INFO_DATA.FILES.name && Boolean(product?.files?.length);
            const isNeedProperties = name === MAIN_INFO_DATA.PROPERTIES.name && Boolean(product?.properties?.length);
            const isNeedSimilarProducts = name === MAIN_INFO_DATA.SIMILAR_PRODUCTS.name && !isProductAvailable;

            return isNeedAdvices || isNeedDescription || isNeedFiles || isNeedProperties || isNeedSimilarProducts;
        },
        [advices, isProductAvailable, product?.files?.length, product?.properties?.length, product?.description?.length]
    );
};
