import classNames from 'classnames';
import {BACK_BUTTON_THEMES, BackButton, IProps as IBackButtonProps} from 'components/BackButton';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {FC} from 'react';

import style from './style.module.scss';

interface IProps extends IBackButtonProps, TPropsWithClassName {
    title: string;
    hasBackButton?: boolean;
}

const columnsStyle = getGridColumnStyles();

export const CategoryPageHeader: FC<IProps> = ({hasBackButton = true, title, className, ...restBackButtonProps}) => {
    return (
        <Grid className={classNames(style.container, className)}>
            {hasBackButton && (
                <BackButton className={columnsStyle} theme={BACK_BUTTON_THEMES.ghost} {...restBackButtonProps} />
            )}
            <Typography
                className={classNames(style.title, columnsStyle)}
                color="gray100"
                data-skeleton-item
                element="h1"
                variant="h1"
            >
                {title}
            </Typography>
        </Grid>
    );
};
