import {TAppThunk} from 'core/redux/types';
import {TResponse} from 'core/types';
import {actionRemoveError, actionSetError} from 'modules/errors/slice';
import {actionLoaderSetHidden, actionLoaderSetVisible} from 'modules/loader/slice';

interface IRequestAction<R extends Record<string, any> = Record<string, any>> {
    type?: string;
    requestCb: () => Promise<TResponse<R>['data']>;
}
export const requestAction =
    <R extends TResponse<any>>({requestCb, type = ''}: IRequestAction<R>): TAppThunk<R['data']> =>
    async (dispatch) => {
        if (type) {
            dispatch(actionLoaderSetVisible(type));
            dispatch(actionRemoveError(type));
        }

        let response;

        try {
            response = await requestCb();
        } catch (e) {
            console.error(e);
        }

        if (!response) {
            return {};
        }

        const {data, errors} = response;

        if (errors && errors.length) {
            if (type) {
                dispatch(actionSetError({errors, moduleName: type}));
                dispatch(actionLoaderSetHidden(type));
            }

            console.error(errors);

            throw errors;
        }

        if (type) {
            dispatch(actionLoaderSetHidden(type));
        }

        return data || {};
    };
