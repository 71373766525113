import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TCategoryListResponse, TPopularCategoriesListResponse} from 'modules/categories/models/CategoryListResponse';
import {ICategoryNode, ICategoryTreeHashMap} from 'modules/categories/models/CategoryTree';
import {Category} from 'new-models';

export class CategoryService extends EvolutionHttpClient {
    public async getCategoriesList(): Promise<TCategoryListResponse> {
        const {data} = await this.get<TCategoryListResponse>({
            url: '/categories',
        });

        return data;
    }

    public static parseCategoryUrl = (
        path: string
    ): {
        categoryCode: Category['code'];
        categoryId: Category['id'];
    } => {
        const regex = /^(?<categoryCode>(.*))-(?<categoryId>\d+$)/gu;

        const captureCategory = regex.exec(path)?.groups || {};

        return {
            categoryCode: captureCategory.categoryCode,
            categoryId: captureCategory.categoryId,
        };
    };

    public static makeParentCategoryIdList = (
        categoryId: Category['id'],
        categoryIdList: Category['id'][],
        categoryList: Category[]
    ): Category['id'][] => {
        const categoryWithParent = categoryList.find(({id, parentId}) => categoryId === id && parentId);
        if (categoryWithParent) {
            const parentCategoryId = (categoryList.find(({id}) => id === categoryWithParent.parentId) as Category).id;
            return this.makeParentCategoryIdList(parentCategoryId, [parentCategoryId, ...categoryIdList], categoryList);
        }

        return categoryIdList;
    };

    public static makeCategoryUrl = (code: Category['code'], id: Category['id']): string => {
        return `${code}-${id}`;
    };

    public static findCategoryById = (
        categoryNodeList: ICategoryNode[],
        id?: Category['id']
    ): ICategoryNode | undefined => {
        for (let i = 0; i < categoryNodeList.length; i++) {
            if (categoryNodeList[i].id === id) {
                return categoryNodeList[i];
            }

            const subItems = CategoryService.findCategoryById(categoryNodeList[i].childrenCategory, id);

            if (subItems) {
                return subItems;
            }
        }

        return undefined;
    };

    public static findCategory = (
        categoryNodeList: ICategoryNode[],
        code: Category['code']
    ): ICategoryNode | undefined => {
        for (let i = 0; i < categoryNodeList.length; i++) {
            if (categoryNodeList[i].code === code) {
                return categoryNodeList[i];
            }

            const subItems = CategoryService.findCategory(categoryNodeList[i].childrenCategory, code);

            if (subItems) {
                return subItems;
            }
        }

        return undefined;
    };

    public static makeCategoryHashMap = (categoriesList: Category[]) => {
        return categoriesList.reduce((acc: ICategoryTreeHashMap, categoryItem) => {
            if (!acc[categoryItem.id ?? 0]) {
                acc[categoryItem.id ?? 0] = {
                    ...categoryItem,
                    childrenCategory: [],
                };
            }
            return acc;
        }, {} as ICategoryTreeHashMap);
    };

    public static makeCategoryTree = (categoriesList: Category[]) => {
        const tree: ICategoryNode[] = [];

        const hashMap = CategoryService.makeCategoryHashMap(categoriesList);

        for (const id in hashMap) {
            if (hashMap[id]) {
                const hasMapElement = hashMap[id];

                if (hasMapElement.parentId) {
                    hashMap[hasMapElement.parentId].childrenCategory.push(hasMapElement);
                } else {
                    tree.push(hasMapElement);
                }
            }
        }

        return tree;
    };

    public async getPopularCategoriesList() {
        const {data} = await this.get<TPopularCategoriesListResponse>({
            url: '/categories/top',
        });

        return data;
    }
}
