import React, {FC, ImgHTMLAttributes, useEffect, useState} from 'react';

type TImageAttributes = ImgHTMLAttributes<HTMLImageElement>;

interface IImageWithFallbackProps extends TImageAttributes {
    fallbackCmp?: FC;
    fallbackSrc?: string;
    isLoading?: boolean;
}

export const ImageWithFallback: FC<IImageWithFallbackProps> = (props) => {
    const {src = '', fallbackSrc = '', fallbackCmp: FallbackCmp, isLoading, alt = 'изображение', ...rest} = props;
    const [imgSrc, setImgSrc] = useState<TImageAttributes['src']>(src);
    const [error, setError] = useState<boolean>(false);

    const showFallbackCmp = isLoading || Boolean(FallbackCmp && error);

    const onError = () => {
        if (!fallbackSrc) {
            setError(true);
            return;
        }

        setError(true);
        setImgSrc(fallbackSrc!);
    };

    useEffect(() => {
        setImgSrc(src);
    }, [src]);

    if (!imgSrc || showFallbackCmp) {
        return FallbackCmp ? <FallbackCmp /> : null;
    }

    return <img alt={alt} {...rest} onError={onError} src={imgSrc} />;
};
