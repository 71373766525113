import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import React from 'react';

import style from './style.module.scss';

interface IProps {
    discountPrice: number;
}

export const TotalDiscount: FC<IProps> = ({discountPrice}) =>
    Boolean(discountPrice) ? (
        <div className={style.root}>
            <Typography variant="p">Скидки</Typography>
            <Typography variant="p-strong">
                -
                {formatPrice((discountPrice / PRICE_DENOMINATOR).toString(), {
                    maximumFractionDigits: 2,
                })}
                <Typography className={style.totalPriceCurrency} variant="p-regular">
                    ₽
                </Typography>
            </Typography>
        </div>
    ) : null;
