import classNames from 'classnames';
import {ScrollTo} from 'components/ScrollTo';
import {ShadowBlock} from 'components/ShadowBlock';
import {Tab} from 'components/Tab';
import {TabsGroup} from 'components/TabsGroup';
import {TPropsWithClassName} from 'core/types';
import {Advice} from 'models';
import {NAV_TABS_MIN_NUMBER, NAV_USED_IN} from 'modules/product-card/constants';
import {useCreateMainInfoBlockList} from 'modules/product-card/hooks/useCreateMainInfoBlockList';
import {useRenderHeaderHeight} from 'modules/product-card/hooks/useRenderHeaderHeight';
import {TMainInfoElementName, TNavUsedIn} from 'modules/product-card/types';
import {ProductDetail} from 'new-models';
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ScrollElementProps, ScrollLink} from 'react-scroll';

import style from './style.module.scss';

const SCROLL_DURATION = 500;

interface IProps extends TPropsWithClassName {
    product: ProductDetail;
    advices?: Advice[] | null;
    isActive: boolean;
    usedIn: TNavUsedIn;
}

export const NavigationTabs: FC<IProps> = ({advices, className, product, isActive, usedIn}) => {
    const tabsGroupRef = useRef<HTMLFormElement>(null);

    const [activeScrollTab, setActiveScrollTab] = useState<TMainInfoElementName>();

    const renderHeaderHeight = useRenderHeaderHeight();

    // TODO: for the time of mixing two mock servers
    // const isProductAvailable = useIsProductAvailable(product);
    const isProductAvailable = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const ScrollTabElement = useCallback(
        ScrollLink((props: ScrollElementProps<any>) => {
            return <ShadowBlock {...props}>{props.children}</ShadowBlock>;
        }),
        []
    );

    const tabList = useCreateMainInfoBlockList({advices, isProductAvailable, product});

    const isRenderTabs = useMemo<boolean>(
        () => usedIn === NAV_USED_IN.header || tabList.length >= NAV_TABS_MIN_NUMBER,
        [tabList.length, usedIn]
    );

    const handleSetActiveScrollTab = useCallback(
        (name: TMainInfoElementName) => () => {
            setActiveScrollTab(name);
        },
        []
    );

    useEffect(() => {
        setActiveScrollTab(tabList[0].name);
    }, [tabList]);

    return isRenderTabs ? (
        <ScrollTo
            behavior="auto"
            container={tabsGroupRef.current}
            disabled={usedIn === NAV_USED_IN.main}
            scrollId={activeScrollTab}
        >
            <TabsGroup
                className={classNames(className, {
                    [style.tabGroupActive]: isActive,
                })}
                name="navigation-tabs"
                ref={tabsGroupRef}
            >
                {tabList.map(({name, label}) => (
                    <ScrollTabElement
                        className={style.link}
                        duration={SCROLL_DURATION}
                        offset={renderHeaderHeight}
                        onSetActive={handleSetActiveScrollTab(name)}
                        smooth
                        spy
                        to={name}
                    >
                        <div data-scroll-mark={name}>
                            <Tab checked={activeScrollTab === name} className={style.tab} label={label} value={name} />
                        </div>
                    </ScrollTabElement>
                ))}
            </TabsGroup>
        </ScrollTo>
    ) : null;
};
