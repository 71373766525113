import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {ProductOfferDetailResponse} from 'models';
import {PriceAndBonuses} from 'modules/price-n-bonuses/components/PriceAndBonuses';
import {USED_IN} from 'modules/price-n-bonuses/constants';
import {AuthSuggestionBlock} from 'modules/product-card/components/AuthSuggestionBlock';
import {ProductDeliveryInfo} from 'modules/product-card/components/ProductDeliveryInfo';
import {ProductCardActions} from 'modules/product-card-actions/components/ProductCardActions';
import {PRODUCT_CARD_VARIANT} from 'modules/products/constants';
import {useIsProductAvailable} from 'modules/products/hooks/useIsProductAvailable';
import {useIsLoggedIn} from 'modules/user/hooks/useIsLoggedIn';
import {DeliveryInfo, ProductXDataResponse} from 'new-models';
import circleCheckIcon from 'public/icons/circle-check.svg';
import React, {memo, ReactNode} from 'react';

import styles from './style.module.scss';

export interface IProps extends Omit<ProductOfferDetailResponse, 'product'>, ProductXDataResponse, TPropsWithClassName {
    isVisualCard?: boolean;
    renderTitle: ReactNode;
    productDeliveryInfo?: DeliveryInfo[];
    actionsRef?: (node?: Element | null) => void;
}

export const ProductCardSidebar = memo<IProps>(function ProductCardSidebar({
    renderTitle,
    className,
    product,
    productDeliveryInfo,
    actionsRef,
}) {
    const {availabilityCount} = product;

    const userIsLoggedIn = useIsLoggedIn();

    const isProductAvailable = useIsProductAvailable(product);

    return (
        <div className={classNames(styles.sidebar, className)}>
            <div className={styles.mainContent}>
                {renderTitle}
                <PriceAndBonuses
                    isProductAvailable={isProductAvailable}
                    price={product.price}
                    usedIn={USED_IN.mainOffer}
                    withUnit
                />
            </div>
            <div className={styles.desktopActions} ref={actionsRef}>
                <ProductCardActions {...product} favoriteBtnSize="large" variant={PRODUCT_CARD_VARIANT.main} />
            </div>
            <div className={styles.additionalContent}>
                {!userIsLoggedIn && <AuthSuggestionBlock />}
                <div className={styles.availability}>
                    {isProductAvailable && Boolean(productDeliveryInfo?.length) && (
                        <ProductDeliveryInfo productDeliveryInfo={productDeliveryInfo} />
                    )}
                    {isProductAvailable && (
                        <div className={styles.availabilityInfo} data-skeleton-item>
                            <SvgIcon svg={circleCheckIcon} />
                            <Typography color="gray60" element="p" variant="elements-p">
                                Доступно к заказу {availabilityCount} шт.
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
            <div className={classNames(styles.actionsAdaptiveContainer)}>
                <ProductCardActions {...product} isHorizontal isStaticWidth variant={PRODUCT_CARD_VARIANT.mainBottom} />
            </div>
        </div>
    );
});
