import {TClosureCallback} from 'core/types';
import {MAIN_INFO_DATA} from 'modules/product-card/constants';
import {useIsNeedMainInfoBlock} from 'modules/product-card/hooks/useIsNeedMainInfoBlock';
import {IMainInfoDataValues, IUseIsNeedMainInfoItemParams} from 'modules/product-card/types';
import {useMemo} from 'react';

export const useCreateMainInfoBlockList: TClosureCallback<IUseIsNeedMainInfoItemParams, IMainInfoDataValues[]> = ({
    product,
    advices,
    isProductAvailable,
}) => {
    const isNeedTab = useIsNeedMainInfoBlock({advices, isProductAvailable, product});
    return useMemo<IMainInfoDataValues[]>(
        () => Object.values(MAIN_INFO_DATA).filter(({name}) => isNeedTab(name)),
        [isNeedTab]
    );
};
