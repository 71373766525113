import {useErrorsHandlerObjects} from 'core/hooks/useErrorsHandler';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {actionSetErrors} from 'modules/authentication/slice';
import {IErrorsHandlerParams, TAuthErrors} from 'modules/authentication/types';
import {useCallback} from 'react';

export const useErrorsHandler = () => {
    const dispatch = useAppDispatch();
    const handler = useErrorsHandlerObjects();

    return useCallback(
        ({errors, additionalCb}: IErrorsHandlerParams) => {
            const errorsObj = handler<TAuthErrors>({additionalCb, errors});
            dispatch(actionSetErrors(errorsObj));
        },
        [dispatch, handler]
    );
};
