import classNames from 'classnames';
import {IOptionsMediaHarvesterProps, MediaHarvester} from 'components/MediaHarvester';
import {NoImage} from 'components/NoImage';
import {PrevDefaultLink} from 'components/PrevDefaultLink';
import {Typography} from 'components/Typography';
import {generateMediaOptions, IMAGE_RESIZE_CODE} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {FavouriteSwitcher} from 'modules/favourites/components/FavouriteSwitcher';
import {FavouriteSwitcherRecoverable} from 'modules/favourites/components/FavouriteSwitcher/Recoverable';
import {PriceAndBonuses} from 'modules/price-n-bonuses/components/PriceAndBonuses';
import {usePriceAndBonuses} from 'modules/price-n-bonuses/hooks/usePriceAndBonuses';
// import {getProductRatingAnchorUrl} from 'modules/product-card/helpers';
import {ProductCardActions} from 'modules/product-card-actions/components/ProductCardActions';
import {ProductImageSwitcher} from 'modules/products/components/ProductImageSwitcher';
import {PRODUCT_CARD_VARIANT, PRODUCT_LIST_VIEW} from 'modules/products/constants';
import {useIsProductAvailable} from 'modules/products/hooks/useIsProductAvailable';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {TProductCardVariant, TProductListView} from 'modules/products/types';
// import {Rating} from 'modules/reviews/components/Rating';
import {ShieldsList} from 'modules/shields/components/ShieldsList';
import {useIsSkeleton} from 'modules/skeleton/hooks/useIsSkeleton';
import {MediaImage, MediaTypeEnum, ProductShort} from 'new-models';
import React, {FC, ReactNode, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends ProductShort, TPropsWithClassName {
    variant?: TProductCardVariant;
    listType: TProductListView;
    isWithSlider?: boolean;
}

export const SimpleProductCard: FC<IProps> = ({
    variant = 'auto',
    listType,
    className,
    isWithSlider = true,
    ...product
}) => {
    const {price, media, article, title, nameplates, type} = product;
    const productUrl = useProductUrl({...product});

    const isProductAvailable = useIsProductAvailable(product);

    const {setSimpleCardUsedIn} = usePriceAndBonuses();

    const isSkeleton = useIsSkeleton();

    const image = useMemo<MediaImage | null>(() => {
        if (!media?.length) {
            return null;
        }

        return media[0] as MediaImage;
    }, [media]);

    const options = useMemo<IOptionsMediaHarvesterProps>(() => {
        if ('auto' === variant || PRODUCT_CARD_VARIANT.favourites === variant) {
            return generateMediaOptions({
                resizeKey: IMAGE_RESIZE_CODE.w410,
                type: MediaTypeEnum.Image,
            });
        }

        return generateMediaOptions({
            resizeKey: IMAGE_RESIZE_CODE.w564,
            type: MediaTypeEnum.Image,
        });
    }, [variant]);

    const renderImage = useMemo<ReactNode>(() => {
        const renderThumbnail = (
            <NoImage className={style.imageContainer} listView={listType} type={type} variant={variant} />
        );

        if (!image) {
            return renderThumbnail;
        }

        if (isWithSlider) {
            return <ProductImageSwitcher className={style.imageContainer} imageList={media} options={options} />;
        }

        return <MediaHarvester className={style.imageContainer} media={image} options={options} />;
    }, [listType, type, variant, image, isWithSlider, options, media]);

    const favouriteSwitcher = useMemo(() => {
        const Component =
            variant === PRODUCT_CARD_VARIANT.favourites ? FavouriteSwitcherRecoverable : FavouriteSwitcher;
        return <Component data-prevent-default-marker data-stop-propagation-marker product={product as ProductShort} />;
    }, [product, variant]);

    return (
        <div
            className={classNames(style.simpleProduct, className, {
                [style.simpleProductListType]: PRODUCT_LIST_VIEW.list === listType,
                [style.simpleProductPreview]: PRODUCT_CARD_VARIANT.preview === variant,
                [style.simpleProductPreviewVisual]: PRODUCT_CARD_VARIANT.previewVisual === variant,
            })}
        >
            <ShieldsList className={style.shieldList} listType={listType} shields={nameplates} variant={variant} />
            <PrevDefaultLink className={style.simpleProductImage} data-skeleton-item href={productUrl} prefetch={false}>
                {renderImage}
            </PrevDefaultLink>
            <div
                className={classNames(style.rightTopButtons, {
                    [style[variant]]: Boolean(variant),
                    [style.hide]: isSkeleton,
                })}
            >
                {favouriteSwitcher}
                {/* <CompareProductsButton product={product} />*/}
            </div>
            <div className={classNames(style.simpleProductFlexWrapper, 'simple-product-flex-wrapper')}>
                <div className={style.simpleProductInfoContainer}>
                    {Boolean(article) && (
                        <div className={style.articleContainer} data-skeleton-item>
                            <Typography className={style.simpleProductArticle} element="div" variant="p">
                                {article}
                            </Typography>
                            {/* <PrevDefaultLink href={getProductRatingAnchorUrl(productUrl)} prefetch={false}>*/}
                            {/*    <Rating isShort rating={rating} reviewCount={reviewCount} />*/}
                            {/* </PrevDefaultLink>*/}
                        </div>
                    )}
                    <PrevDefaultLink href={productUrl} prefetch={false}>
                        <Typography className={style.simpleProductTitle} data-skeleton-item element="div" variant="p">
                            {title}
                        </Typography>
                    </PrevDefaultLink>
                    {/* TODO: for the time of mixing two mock servers*/}
                    {/* {isProductAvailable && (*/}
                    {/*    <AvailabilityButton*/}
                    {/*        availability={availability}*/}
                    {/*        className={style.simpleProductAvailability}*/}
                    {/*        data-prevent-default-marker*/}
                    {/*        productId={id}*/}
                    {/*    />*/}
                    {/* )}*/}
                </div>
                <div className={style.simpleProductPriceContainer} data-skeleton-item>
                    {isProductAvailable && (
                        <PrevDefaultLink className={style.simpleProductPriceLink} href={productUrl} prefetch={false}>
                            <PriceAndBonuses
                                className={style.simpleProductPrice}
                                isProductAvailable={isProductAvailable}
                                price={price}
                                usedIn={setSimpleCardUsedIn(listType, variant)}
                            />
                        </PrevDefaultLink>
                    )}
                    {/* TODO: for the time of mixing two mock servers*/}
                    {/* <div className={style.simpleProductAvailabilityContainer}>*/}
                    {/*     {isProductAvailable && (*/}
                    {/*        <AvailabilityButton*/}
                    {/*            availability={availability}*/}
                    {/*            className={style.simpleProductAvailability}*/}
                    {/*            data-prevent-default-marker*/}
                    {/*            productId={id}*/}
                    {/*        />*/}
                    {/*     )}*/}
                    {/* </div>*/}
                    <div
                        className={classNames(
                            style.simpleProductActionsContainer,
                            !isProductAvailable && style.notAvailable
                        )}
                    >
                        <ProductCardActions
                            className={style.simpleProductActions}
                            variant={variant || listType}
                            {...product}
                            // TODO: for the time of mixing two mock servers
                            type="simple"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
