import {Button} from 'components/Button';
import {InputCaption} from 'components/InputCaption';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import {StoreSelection} from 'modules/checkout/components/StoreSelection';
import {PICKUP_POINT_ID_FIELD_NAME} from 'modules/checkout/constants/fields';
import {CheckoutContext} from 'modules/checkout/context';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import {useCheckoutMoreDetailsPickupPointModalApi} from 'modules/checkout/hooks/useCheckoutMoreDetailsPickupPointModalApi';
import {useCheckoutSelectPickupPointModalApi} from 'modules/checkout/hooks/useCheckoutSelectPickupPointModalApi';
import {DeliveryType} from 'new-models';
import arrowRightIcon from 'public/icons/arrow-right-20.svg';
import React, {memo, useContext, useEffect, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    deliveryId?: string;
}

export const CheckoutPointSelection = memo<IProps>(function CheckoutPointSelection({className, deliveryId}) {
    const {open: handleOpenSelectPickupPointModal} = useCheckoutSelectPickupPointModalApi();
    const {open: handleOpenMoreDetailsPickupPointModal} = useCheckoutMoreDetailsPickupPointModalApi();

    const {getFieldMeta, getFieldHelpers, submitCount} = useCheckoutFormikContext();
    const fieldMeta = getFieldMeta(PICKUP_POINT_ID_FIELD_NAME);

    const isOnceSubmitted = Boolean(submitCount);

    const {selectedPickupPointId, pickupPointData, deliveryMethodData, isPickupPointListLoading} =
        useContext(CheckoutContext);
    const currentDeliveryType = (deliveryId && deliveryMethodData[deliveryId]?.type) || null;

    useEffect(() => {
        switch (currentDeliveryType) {
            case DeliveryType.PICKUP: {
                getFieldHelpers(PICKUP_POINT_ID_FIELD_NAME).setValue(selectedPickupPointId || '');
                break;
            }
            default:
                break;
        }
    }, [currentDeliveryType, getFieldHelpers, selectedPickupPointId]);

    const renderDetails = useMemo(() => {
        switch (currentDeliveryType) {
            case DeliveryType.PICKUP: {
                return !selectedPickupPointId ? (
                    <div className={className}>
                        <Button
                            className={style.button}
                            data-skeleton-item
                            isLoading={isPickupPointListLoading}
                            isStretched
                            onClick={handleOpenSelectPickupPointModal}
                            rightIcon={<SvgIcon svg={arrowRightIcon} />}
                            theme="primary"
                        >
                            Выбрать магазин
                        </Button>
                        {isOnceSubmitted && fieldMeta.error && <InputCaption errorText={fieldMeta.error} />}
                    </div>
                ) : (
                    <StoreSelection
                        className={className}
                        onChangeStore={handleOpenSelectPickupPointModal}
                        onDetailStore={handleOpenMoreDetailsPickupPointModal}
                        point={pickupPointData[selectedPickupPointId]}
                    />
                );
            }
            default:
                return null;
        }
    }, [
        className,
        currentDeliveryType,
        fieldMeta.error,
        handleOpenMoreDetailsPickupPointModal,
        handleOpenSelectPickupPointModal,
        isOnceSubmitted,
        isPickupPointListLoading,
        pickupPointData,
        selectedPickupPointId,
    ]);

    return <>{renderDetails}</>;
});
