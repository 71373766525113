import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {SvgIcon} from 'components/SvgIcon';
import {useScroll} from 'core/hooks/useScroll';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import searchIcon from 'public/icons/search.svg';
import React, {FC, useCallback, useRef} from 'react';

const columnStyle = getGridColumnStyles();
import {COLLAPSE_VARIANTS, CollapseAnimatedOnScrollMix} from 'components/CollapseAnimatedMix';
import {ContactUsPhoneLink} from 'components/ContactUsPhoneLink';
import {RUNTIME_CSS_VARS} from 'core/constants';
import {useElementHeightWithCssVar} from 'core/hooks/useElementHeightWithCssVar';
import {useIsIncludesRoute, useIsNeedRoute} from 'core/hooks/useIsNeedRoute';
import {HeaderLocationItem} from 'modules/locations/components/HeaderLocationItem';
import Link from 'next/link';
import logoMiniIcon from 'public/icons/logo-mobile.svg';
import {BRAND_LIST_ROUTE, DASHBOARD_ROOT_ROUTE, HOME_ROUTE, PRODUCT_PATH} from 'routing/constants';

import style from './style.module.scss';

export const MobileHeader: FC = () => {
    // const isCollapsed = useIsNeedRoute([HOME_ROUTE]);
    const isNeedShadow = !useIsNeedRoute([PRODUCT_PATH, BRAND_LIST_ROUTE]);
    const shouldHideHeaderInProfile = useIsIncludesRoute([DASHBOARD_ROOT_ROUTE]);

    const isBottomCollapseOnTop = useIsNeedRoute([PRODUCT_PATH]);
    const variant = isBottomCollapseOnTop ? COLLAPSE_VARIANTS.onTop : COLLAPSE_VARIANTS.onScrollBottom;
    const isCollapsed = true;
    const isNotAtTop = useScroll(15, 0);
    const dispatch = useAppDispatch();
    // const {theme} = useTheme();
    const theme = 'light';
    const handleSearchClick = useCallback(() => {
        dispatch(showModal(MODALS.CATALOG_MENU.name));
    }, [dispatch]);

    const headerRef = useRef<HTMLDivElement>(null);

    useElementHeightWithCssVar(headerRef, RUNTIME_CSS_VARS.headerHeightAdaptive);

    return (
        <header
            className={classNames(style.fixed, {
                [style.isNotAtTop]: isNotAtTop,
                [style.isNeedShadow]: isNeedShadow,
                [style.hideHeaderOnMobile]: shouldHideHeaderInProfile,
            })}
            ref={headerRef}
        >
            <Grid noRowGap>
                <div
                    className={classNames(style.header, columnStyle, {
                        [style.isNotAtTop]: isNotAtTop,
                        [style.isCollapsed]: isCollapsed,
                        [style[theme]]: theme,
                    })}
                >
                    <Link href={HOME_ROUTE}>
                        <a className={style.logo}>
                            <SvgIcon svg={logoMiniIcon} />
                        </a>
                    </Link>
                    <div className={style.search} onClick={handleSearchClick} role="presentation">
                        <SvgIcon svg={searchIcon} />
                    </div>
                </div>
                <CollapseAnimatedOnScrollMix
                    className={classNames(style.bottomHeaderContainer, columnStyle)}
                    variant={variant}
                >
                    <ul className={style.bottomHeader}>
                        <li>
                            <HeaderLocationItem />
                        </li>
                        <li>
                            <ContactUsPhoneLink />
                        </li>
                    </ul>
                </CollapseAnimatedOnScrollMix>
            </Grid>
        </header>
    );
};
