import {DEBOUNCE_DELAY} from 'core/constants';
import {isMobileByScreen} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {checkIsSpace} from 'core/regex';
import debounce from 'lodash/debounce';
import {ProductCardService} from 'modules/product-card/services/ProductCardService';
import {MIN_FETCH_SYMBOLS_AMOUNT} from 'modules/search-popup/constants';
import {
    selectIsSearchPopUpLoading,
    selectList,
    selectSearchCategoryList,
    selectSearchQuery,
    selectSearchSuggestions,
} from 'modules/search-popup/selectors';
import {actionClearSearchData, actionSetSearchQuery} from 'modules/search-popup/slice';
import {getSearchResultPopupOnSubmit, getSearchResultsPopup} from 'modules/search-popup/thunks';
import {ProductShort} from 'new-models';
import Router, {useRouter} from 'next/router';
import {SearchUrlService} from 'plugins/modules/search/services/SearchUrlService';
import {ChangeEventHandler, MouseEvent, MouseEventHandler, useCallback, useEffect, useState} from 'react';
import {PRODUCT_ROUTE, SEARCH_ROUTE} from 'routing/constants';

// eslint-disable-next-line max-lines-per-function
export function useSearchModule(searchInput: HTMLInputElement | null, isPanelVisible?: boolean, onClose?: () => void) {
    const dispatch = useAppDispatch();

    const searchValue = useAppSelector(selectSearchQuery);
    const searchProducts = useAppSelector(selectList<ProductShort>);
    const searchSuggestions = useAppSelector(selectSearchSuggestions);
    const searchCategories = useAppSelector(selectSearchCategoryList);
    const searchDataLoading = useAppSelector(selectIsSearchPopUpLoading);

    const [isSearchPanelVisible, setIsSearchPanelVisible] = useState(isPanelVisible);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    useEffect(() => {
        setIsSearchPanelVisible(
            searchValue.length >= MIN_FETCH_SYMBOLS_AMOUNT && document.activeElement === searchInput
        );
    }, [searchInput, searchValue.length]);

    const router = useRouter();

    const fetchSearch = useCallback(
        async (query: string) => {
            if (query && MIN_FETCH_SYMBOLS_AMOUNT > query.length) {
                return;
            }

            const isFulfilled = await dispatch(getSearchResultsPopup(new URLSearchParams({query})));

            if (isFulfilled) {
                setIsProcessing(!isFulfilled);
            }
        },
        [dispatch]
    );

    const makeSearchProductUrl = useCallback(
        (code: ProductShort['code'], productId: ProductShort['id']): string =>
            `${PRODUCT_ROUTE}/${ProductCardService.makeProductCardUrl(code, productId)}`,
        []
    );

    const clearSearch = useCallback(
        (event?: MouseEvent<HTMLElement>): void => {
            if (event) {
                event.preventDefault();
            }
            dispatch(actionClearSearchData());
            dispatch(actionSetSearchQuery(''));
        },
        [dispatch]
    );

    const clearReduxSearch = useCallback(
        (query: string) => {
            if (0 === query.length) {
                dispatch(actionClearSearchData());
            }
        },
        [dispatch]
    );

    const handleSearchClick = useCallback(() => {
        if (!isPanelVisible && !isMobileByScreen()) {
            fetchSearch(searchValue);
        }
    }, [fetchSearch, isPanelVisible, searchValue]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetchSearch = useCallback(
        debounce((value: string) => fetchSearch(value), DEBOUNCE_DELAY),
        []
    );

    const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        ({target}) => {
            setIsProcessing(true);
            if (!checkIsSpace(target.value)) {
                dispatch(actionSetSearchQuery(target.value));
                debouncedFetchSearch(target.value);
            }
        },

        [debouncedFetchSearch, dispatch]
    );

    const onFocusInput = useCallback(() => {
        setIsSearchPanelVisible(
            Boolean(
                (searchValue.length >= MIN_FETCH_SYMBOLS_AMOUNT && searchProducts?.length) ||
                    searchSuggestions?.length ||
                    searchCategories?.length
            )
        );
    }, [searchCategories?.length, searchProducts?.length, searchSuggestions?.length, searchValue.length]);

    const handlePanelClose = useCallback(() => {
        clearReduxSearch(searchValue);
    }, [clearReduxSearch, searchValue]);

    const handleClose = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            event.preventDefault();
            clearSearch(event);
            onClose?.();
            dispatch(actionClearSearchData());
        },
        [clearSearch, dispatch, onClose]
    );

    const onClickOutSearch = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
        setIsSearchPanelVisible(false);
    }, []);

    const handleSubmit = useCallback<MouseEventHandler<HTMLFormElement>>(
        async (event) => {
            event.preventDefault();
            debouncedFetchSearch.cancel();

            const isNotAllowed = searchValue.length < MIN_FETCH_SYMBOLS_AMOUNT;

            if (isNotAllowed) {
                return;
            }
            const isOneProductFound = 1 === searchProducts.length;

            if (isOneProductFound) {
                const firstProduct = searchProducts[0];
                const {code, id} = firstProduct;
                await router.push(makeSearchProductUrl(code, id));
                return;
            }

            const form = event.target as HTMLFormElement;
            const inputValue = form.search.value as string;

            const {redirect, isAborted} = await dispatch(
                getSearchResultPopupOnSubmit(new URLSearchParams({query: inputValue}))
            );

            if (isAborted) {
                return;
            }

            if (redirect) {
                await router.push(redirect);
                return;
            }
            setIsSearchPanelVisible(false);
            await router.push(`${SEARCH_ROUTE}?${SearchUrlService.COMMON_PARAM_NAMES.QUERY}=${searchValue}`);
        },
        [debouncedFetchSearch, dispatch, makeSearchProductUrl, router, searchProducts, searchValue]
    );

    const handleCrossClick = useCallback(() => {
        dispatch(actionSetSearchQuery(''));
        dispatch(actionClearSearchData());
        onClose?.();
    }, [dispatch, onClose]);

    useEffect(() => {
        const handleRouteChangeComplete = (url: string) => {
            const [searchPathName] = url.split('?');
            const isSearchPage = searchPathName === SEARCH_ROUTE;

            if (!isSearchPage) {
                clearSearch();
            }
        };

        const handleRouteChangeStart = () => {
            onClose?.();
            handlePanelClose();
            searchInput?.blur();
        };

        Router.events.on('routeChangeStart', handleRouteChangeStart);
        Router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            Router.events.off('routeChangeStart', handleRouteChangeStart);
            Router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [clearSearch, debouncedFetchSearch, handlePanelClose, onClose, searchInput]);

    return {
        clearSearch,
        handleClose,
        handleCrossClick,
        handlePanelClose,
        handleSearchClick,
        handleSubmit,
        isProcessing,
        isSearchPanelVisible,
        onChange,
        onClickOutSearch,
        onFocusInput,
        searchDataLoading,
    };
}
