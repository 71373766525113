import classNames from 'classnames';
import {Button} from 'components/Button';
import {TPropsWithClassName} from 'core/types';
import {TotalDelivery} from 'modules/cart/components/TotalDelivery';
import {TotalDiscount} from 'modules/cart/components/TotalDiscount';
import {TotalOptions} from 'modules/cart/components/TotalOptions';
import {TotalPromo} from 'modules/cart/components/TotalPromo';
import {IUseCartOrderMakingReturns} from 'modules/cart/hooks/useCartTotalInfo';
import {TotalPriceBlock} from 'modules/price-n-bonuses/components/TotalPrice';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName, IUseCartOrderMakingReturns {}

export const CartTotalInfo = memo<IProps>(function CartTotalInfo({className, ...props}) {
    const {
        delivery,
        totalPrice,
        discountPrice,
        productSum,
        productCount,
        volumeValue,
        weightValue,
        handleStartCheckout,
    } = props;

    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            <div className={style.content}>
                <div className={style.main}>
                    <TotalOptions
                        productCount={productCount}
                        productSum={productSum}
                        volumeValue={volumeValue}
                        weightValue={weightValue}
                    />
                    <TotalPromo />
                    <TotalDiscount discountPrice={discountPrice} />

                    <TotalPriceBlock total={totalPrice} usedIn="cartResume" />

                    <Button className={style.submit} disabled={!productCount} isStretched onClick={handleStartCheckout}>
                        Оформить заказ
                    </Button>
                </div>

                <TotalDelivery delivery={delivery} />
            </div>
        </div>
    );
});
