import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {ActionDetail} from 'new-models';
import React, {memo, useMemo} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles({});

interface IProps extends TPropsWithClassName {
    data: ActionDetail;
}

export const PromoDescription = memo<IProps>(function PromoDescription({data, className}) {
    const {fullDescription, image, title} = data;

    const isShowDescription = Boolean(fullDescription);
    const isShowBlock = Boolean(isShowDescription || image);

    const descriptionBlock = useMemo(
        () =>
            Boolean(fullDescription) && (
                <Typography className={columnStyle} color="gray80">
                    {fullDescription}
                </Typography>
            ),
        [fullDescription]
    );

    return isShowBlock ? (
        <Grid as="article" className={classNames(style.root, className)} data-skeleton-item>
            {image && <MediaHarvester className={classNames(style.picture, columnStyle)} media={image} title={title} />}
            {descriptionBlock}
        </Grid>
    ) : null;
});
