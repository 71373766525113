import {ISegmentControlValueList} from 'components/SegmentedControl';
import {SvgIcon} from 'components/SvgIcon';
import {ISocialLinkModel, TStrategy} from 'modules/authentication/types';
import classmatesLogoIcon from 'public/icons/classmates-logo.svg';
import googleLogoIcon from 'public/icons/google-logo.svg';
import mailRuLogoIcon from 'public/icons/mail-ru-logo.svg';
import vkLogoIcon from 'public/icons/vk-logo.svg';
import yandexLogoIcon from 'public/icons/yandex-logo.svg';

export const STRATEGIES = {
    authByLogin: 'authByLogin',
    authBySms: 'authBySms',
    changePhone: 'changePhone',
    enterCodeFromSms: 'enterCodeFromSms',
    passwordRecovery: 'passwordRecovery',
    registrationBySms: 'registrationBySms',
} as const;

export const SCENARIOS = {
    authentication: 'authentication',
    profile: 'profile',
    recovery: 'recovery',
    registration: 'registration',
} as const;

export const VIEWS = {
    alreadyRegistered: 'alreadyRegistered',
    auth: 'auth',
    authLegal: 'authLegal',
    emailConfirm: 'emailConfirm',
    enterBlocked: 'enterBlocked',
    notExist: 'notExist',
    passwordCreate: 'passwordCreate',
    passwordRecovery: 'passwordRecovery',
    phoneConfirm: 'phoneConfirm',
    recoverySuccess: 'recoverySuccess',
    registration: 'registration',
    registrationLegal: 'registrationLegal',
    support: 'support',
} as const;

export const MODAL_STATES = {
    auth: 'auth',
    base: 'base',
    legal: 'legal',
    productSubscription: 'productSubscription',
    smsCodeShowing: 'smsCodeShowing',
} as const;

export const PHONE_CONFIRM_MODAL_TITLES: Partial<Record<TStrategy, string>> = {
    authBySms: 'Вход по СМС',
    changePhone: 'Изменения номера телефона',
    enterCodeFromSms: 'Введите код из SMS',
    passwordRecovery: 'Восстановление пароля ',
};

export const MIN_PHONE_LENGTH = 10;

export const ERRORS = {
    codeRequired: 'Пожалуйста, введите код',
    confirmPasswordRequired: 'Пожалуйста, повторите пароль',
    emailRequired: 'Пожалуйста, введите e-mail',
    enteredSameEmail: 'Введён тот же email',
    enteredSamePhone: 'Введён тот же номер телефона',
    feedbackSubjectRequired: 'Пожалуйста, выберите тему обращения',
    firstNameRequired: 'Пожалуйста, введите имя',
    incorrectEmail: 'Пожалуйста, введите корректный e-mail',
    incorrectPhone: 'Пожалуйста, введите корректный номер телефона',
    invalidINN: 'Неправильный ИНН',
    invalidKPP: 'Неправильный КПП',
    lastNameRequired: 'Пожалуйста, введите фамилию',
    longMessage: 'Сообщение слишком длинное',
    longPass: 'Пароль слишком длинный',
    longPhone: 'Номер телефона слишком длинный',
    messageRequired: 'Пожалуйста, введите сообщение',
    passwordRequired: 'Пожалуйста, введите пароль',
    passwordsDontMatch: 'Пароли не совпадают. Попробуйте еще раз.',
    phoneRequired: 'Пожалуйста, введите номер телефона',
    phoneType: 'Номер телефона должен быть числом',
    shortEmail: 'Слишком короткий email',
    shortPass: 'Пароль слишком короткий',
    shortPhone: 'Номер телефона слишком короткий',
};

export const MAX_PASSWORD_LENGTH = 50;
export const MIN_PASSWORD_LENGTH = 6;

export const CODE_PHRASES = {
    REQUIRED_CODE: 'Пожалуйста, введите код',
};

export const SMS_INPUT_MASK = '••••';
export const INPUT_DEFAULT_FILLER = '•';
export const SOCIAL_LINKS: ISocialLinkModel[] = [
    {icon: <SvgIcon svg={vkLogoIcon} />, name: 'vk'},
    {icon: <SvgIcon svg={classmatesLogoIcon} />, name: 'ok'},
    {icon: <SvgIcon svg={yandexLogoIcon} />, name: 'yandex'},
    {icon: <SvgIcon svg={mailRuLogoIcon} />, name: 'mail'},
    {icon: <SvgIcon svg={googleLogoIcon} />, name: 'google'},
];

export const AUTH_ERROR_CODES = {
    ERR_TIMEOUT: 'ERR_TIMEOUT',
    errorAuth: 'errorAuth',
    exception: 'exception',
    invalid: 'invalid',
    limitAttempt: 'limitAttempt',
    manyRequests: 'manyRequests',
    notConfirmed: 'notConfirmed',
} as const;

export const STRATEGY_TABS: ISegmentControlValueList<TStrategy>[] = [
    {
        id: 0,
        label: 'Войти по СМС',
        value: STRATEGIES.authBySms,
    },
    {
        id: 1,
        label: 'Войти по паролю',
        value: STRATEGIES.authByLogin,
    },
];
