import {MediaFile} from 'new-models';

export const APP_VERSION = '1.0.0';

export const APP_BUILD_VERSION = '1';

export const TOKEN_KEY = 'x-auth-token';

export const THEME_COLOR = '#ffffff';

export const DEBOUNCE_DELAY = 400;

export const MOBILE_MAX_WIDTH = 1279;

export const AVAILABILITY_DELIVERY_TEXT = {
    availability: 'Наличие',
    delivery: 'Доставка',
    pickup: 'Самовывоз',
    reportReceipt: 'Сообщить о поступлении',
    whereIsInTheShop: 'Где этот товар в магазине?',
} as const;

export const TOAST_CLOSE_TIME = 3000;

export type TWebVitalsName = 'CLS' | 'FCP' | 'FID' | 'LCP' | 'TTFB';

export type TWebVitalsMetric = {
    name: TWebVitalsName;
    [key: string]: any;
};

export type TWebVitalsBenchmark = Record<
    TWebVitalsName,
    {
        max: number;
        min: number;
    }
>;

export const WEB_VITALS_BENCHMARKS: TWebVitalsBenchmark = {
    CLS: {
        max: 0.1,
        min: 0,
    },
    FCP: {
        max: 1000,
        min: 0,
    },
    FID: {
        max: 100,
        min: 0,
    },
    LCP: {
        max: 2500,
        min: 0,
    },
    TTFB: {
        max: 500,
        min: 200,
    },
};

export const MAX_COUNT_DISPLAY = 99;

export const PRICE_DENOMINATOR = 100;
export const BONUS_DENOMINATOR = 100;

export const SECOND = 1000;

export const SECONDS_PER_MINUTE = 60;

export const SECONDS_PER_HOUR = 3600;

export const PREVENT_DEFAULT_HANDLER_MARKER = 'data-prevent-default-marker';

export const STOP_PROP_HANDLER_MARKER = 'data-stop-propagation-marker';

export const SHOP_LINK = 'https://shop.ru';

export const PERSONAL_DATA_LINK = `${SHOP_LINK}/rules/accept/`;

export const SELLING_RULES_LINK = `${SHOP_LINK}/rules/about/`;

export const WISH_LISTS_LINK = `${SHOP_LINK}/personal/list/`;

export const SUPPORT_FORM_LINK = `${SHOP_LINK}/#small-dialog-error`;

export const LAYOUT_SHIFTING_TRANSITION_PROP_NAMES = [
    'height',
    'width',
    'max-height',
    'max-width',
    'min-height',
    'min-width',
    'padding',
    'margin',
] as const;

/**
 * Ref: src/styles/normalize.scss
 */
export const RUNTIME_CSS_VARS = {
    bottomBarHeight: '--bottom-bar-height',
    calcModalTitleHeight: '--calc-modal-title-height',
    checkoutHeaderHeight: '--checkout-header-height',
    checkoutTabBarHeight: '--checkout-tab-bar-height',
    fixedHeaderContentMarginTop: '--content-margin-top',
    headerHeight: '--header-height',
    headerHeightAdaptive: '--header-height-adaptive',
    headerHeightModal: '--header-height-modal',
    headerSearchMobileHeight: '--header-search-mobile-height',
    productCardFixedHeader: '--product-card-fixed-header',
    viewPortHeight: '--view-port-height',
} as const;

export const FORCE_RELOAD_GET_PARAM = 'reload';

export const REDIRECT_REQUEST_COOKIE_NAME = '__redirect-me-to';

export const TODAY = 'Сегодня';
export const UNDERSTAND = 'Понятно';

export const HIDE = 'Скрыть';

// TODO: править, когда актуализируют модель, удалить | 'image/jpeg' | 'image/png
export const MIME_TYPES: Record<
    | 'msWord'
    | 'msExcel'
    | 'msPowerPoint'
    | 'octetStream'
    | 'openDoc'
    | 'openPresentation'
    | 'openSheet'
    | 'pdf'
    | 'zip'
    | 'jpg'
    | 'png',
    MediaFile['mimetype'] | 'image/jpeg' | 'image/png'
> = {
    msExcel: 'application/vnd.ms-excel',
    msPowerPoint: 'application/vnd.ms-powerpoint',
    msWord: 'application/msword',
    octetStream: 'application/octet-stream',
    openDoc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    openPresentation: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    openSheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pdf: 'application/pdf',
    zip: 'application/zip',
    // eslint-disable-next-line sort-keys
    jpg: 'image/jpeg',
    png: 'image/png',
};
export const MAX_PRODUCTS_AMOUNT = 50_000;

export const MIN_DESKTOP_WIDTH = 1280;
export const MIN_TABLET_SM_MIN_WIDTH = 768;

export const PAGE_LIMIT_DEFAULT = 36;

export const SEPARATORS_LIST = {
    COMMA_AND_SPACE: ', ',
};

export const RESPONSE_STATUS_CODE = {
    FOUND: 302,
    NOT_FOUND: 404,
};

export const EMAIL_SUPPORT = 'info@karacahome.ru';
