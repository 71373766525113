import {Typography} from 'components/Typography';
import {Shield} from 'modules/shields/components/Shield';
import {MenuSection} from 'new-models';
import Link from 'next/link';
import {memo, useCallback} from 'react';

import style from './style.module.scss';

interface IProps {
    sections: MenuSection[];
}
export const PromotionsBlock = memo<IProps>(function DiscountInfoBlock({sections}) {
    const renderContent = useCallback(
        ({nameplates, title}: MenuSection) => (
            <div className={style.inner}>
                {nameplates?.[0] && <Shield theme="sale" title={nameplates[0].title} />}
                <Typography color="gray100" element="p" variant="p">
                    {title}
                </Typography>
            </div>
        ),
        []
    );

    return sections.map(({url, ...restSection}, idx) =>
        url?.value ? (
            <Link href={url.value} key={idx} passHref>
                <a className={style.root}>{renderContent(restSection)}</a>
            </Link>
        ) : (
            <div className={style.root} key={idx}>
                {renderContent(restSection)}
            </div>
        )
    );
});
