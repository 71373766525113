import classNames from 'classnames';
import {NO_IMAGE_SIZE, NoImage} from 'components/NoImage';
import {Typography} from 'components/Typography';
import {SECONDS_PER_MINUTE} from 'core/constants';
import {plural} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {Advice} from 'models';
import {ImageWithFallback} from 'modules/images/components/ImageWithFallback';
import {TTypeAdviceList} from 'modules/product-card/types';
import {IShieldProps} from 'modules/shields/components/Shield';
import {ShieldsList} from 'modules/shields/components/ShieldsList';
import {SHIELD_THEMES} from 'modules/shields/constants';
import {Slider} from 'modules/slider/components/Slider';
import {MediaTypeEnum} from 'new-models';
import React, {FC, forwardRef, ReactNode, useMemo} from 'react';

import style from './style.module.scss';

interface IProps {
    advices: Advice[];
    sliceTo?: number;
    lastSlideItemButton?: ReactNode;
    type?: TTypeAdviceList;
}

const ImageFallback: FC = () => {
    return <NoImage className={style.image} size={NO_IMAGE_SIZE.default} />;
};

export const AdvicesList = forwardRef<HTMLDivElement, TPropsWithClassName<IProps>>(function AdvicesList(
    {sliceTo, className, advices, lastSlideItemButton = null, type},
    ref
) {
    const renderSliderList = useMemo<ReactNode[]>(() => {
        const advicesSliderList: ReactNode[] = advices
            .slice(0, sliceTo)
            .map(({id, name, url, image, description, tag, timeToReadInSeconds}, index) => {
                const timeFormatted = timeToReadInSeconds ? timeToReadInSeconds / SECONDS_PER_MINUTE : 0;
                const shields = [] as IShieldProps[];

                if (tag) {
                    shields.push({
                        icon: {
                            alternative: 'hashtag',
                            title: 'hashtag',
                            type: MediaTypeEnum.Image,
                            url: '/icons/hashtag.svg',
                        },
                        theme: SHIELD_THEMES.info,
                        title: tag,
                        variant: 'p-medium-strong',
                    });
                }

                if (0 < timeFormatted) {
                    shields.push({
                        icon: {alternative: 'book', title: 'book', type: MediaTypeEnum.Image, url: '/icons/book.svg'},
                        theme: SHIELD_THEMES.info,
                        title: `${timeFormatted} ${plural(timeFormatted, ['минута', 'минуты', 'минут'])}`,
                        variant: 'p-medium-strong',
                    });
                }

                return (
                    <a data-skeleton-item href={url} key={`${id}-${index}`} rel="noreferrer" target="_blank">
                        <div className={classNames(style[type ?? ''], style.advicesItem)} data-skeleton-item>
                            {Boolean(shields.length) && (
                                <ShieldsList className={style.advicesItemShieldList} shields={shields} />
                            )}
                            <ImageWithFallback
                                alt={image?.alternative}
                                className={style.image}
                                fallbackCmp={ImageFallback}
                                loading="lazy"
                                src={image?.path}
                                title={image?.title}
                            />
                            <Typography
                                className={style.advicesItemName}
                                color="gray100"
                                data-skeleton-item
                                element="p"
                                variant="p-strong"
                            >
                                {name}
                            </Typography>
                            <Typography className={style.advicesItemText} color="gray80" element="p" variant="p-medium">
                                {description}
                            </Typography>
                        </div>
                    </a>
                );
            });

        if (lastSlideItemButton) {
            advicesSliderList.push(lastSlideItemButton);
        }

        return advicesSliderList;
    }, [type, advices, lastSlideItemButton, sliceTo]);

    return (
        <div className={classNames(style.advicesList, className)} id="advices" ref={ref}>
            <Typography className={style.advicesListTitle} color="gray100" data-skeleton-item element="h3" variant="h3">
                Советы
            </Typography>
            <div className={style.advicesListSliderContainer}>
                <div className={style.advicesListSliderContainerLeftSpacer} />
                <Slider
                    className={style.advicesListSliderSlider}
                    isNavigation
                    isPagination
                    slidesPerGroup={1}
                    slidesPerView="auto"
                >
                    {renderSliderList}
                </Slider>
                <div className={style.advicesListSliderContainerRightSpacer} />
            </div>
        </div>
    );
});
