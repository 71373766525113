import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TClosureCallback, TPropsWithClassName} from 'core/types';
import React, {ChangeEventHandler, Fragment, useCallback, useEffect, useId, useMemo, useState} from 'react';

import style from './style.module.scss';

export interface ISegmentControlValueList<Value extends string = string> {
    id: number;
    value: Value;
    label?: string;
}

export interface IProps<Value extends string = string> extends TPropsWithClassName {
    valueList: ISegmentControlValueList<Value>[];
    onChange?: (value: Value) => void;
    defaultCheckedId: ISegmentControlValueList<Value>['id'];
}

export const SegmentedControl = <Value extends string = string>({
    valueList,
    onChange,
    className,
    defaultCheckedId,
}: IProps<Value>) => {
    const uid = useId();
    const htmlFor = useId();

    const defaultCheckedIndex = useMemo<number>(() => {
        const index = valueList.findIndex((valueItem) => valueItem.id === defaultCheckedId);

        return Math.abs(index);
    }, [defaultCheckedId, valueList]);

    const [checkedIndex, setCheckedIndex] = useState<number>(defaultCheckedIndex);
    const [count, setItemCount] = useState<number>(valueList.length);

    const handleSelectedChange = useCallback<TClosureCallback<number, ChangeEventHandler<HTMLInputElement>>>(
        (index) => {
            return (event) => {
                onChange?.(event.target.value as Value);
                setCheckedIndex(index);
            };
        },
        [onChange, setCheckedIndex]
    );

    useEffect(() => {
        setItemCount(valueList.length);
    }, [valueList.length]);

    return (
        <form className={classNames(style.segmentControlForm, className)} data-skeleton-item>
            <div
                className={style.segmentControlFrame}
                style={{
                    transform: `translateX(calc(100% * ${checkedIndex}))`,
                    width: `calc(100% / ${count})`,
                }}
            />
            {valueList.map((valueItem, index) => {
                const id = `${htmlFor}:${valueItem.value}:${index}`;

                return (
                    <Fragment key={id}>
                        <input
                            className={style.segmentControlRadioInput}
                            data-order={index}
                            defaultChecked={valueItem.id === defaultCheckedId}
                            id={id}
                            name={uid}
                            onChange={handleSelectedChange(index)}
                            type="radio"
                            value={valueItem.value}
                        />
                        <label className={style.segmentControlLabel} htmlFor={id}>
                            <Typography element="span" variant="p-bold-strong">
                                {valueItem.label || valueItem.value}
                            </Typography>
                        </label>
                    </Fragment>
                );
            })}
        </form>
    );
};
