/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface PickupPointsDataResponse {
  /**
   * Список пунктов самовывоза
   * @minItems 0
   */
  pickupPoints: PickupPoint[];
}

export interface CourierSlotsDataResponse {
  /**
   * Временные слоты на конкретные даты для курьерской доставки
   * @minItems 0
   */
  timeslots: DateSlots[];
}

/**
 * Тип доставки
 * @example "PICKUP"
 */
export enum DeliveryType {
  PICKUP = "PICKUP",
  COURIER = "COURIER",
  POST = "POST",
  POSTMATE = "POSTMATE",
}

export interface DeliveriesDataResponse {
  /** @minItems 0 */
  deliveries?: DeliveryMethodShort[];
}

export interface PaymentsDataResponse {
  /** @minItems 0 */
  payments?: PaymentMethodShort[];
}

export interface ContentPage {
  /**
   * Название страницы
   * @example "О нас"
   */
  title: string;
  /**
   * Контент страницы
   * @example "<html>...</html>"
   */
  content: string;
  type: ContentPageType;
  image?: MediaGroupVisual;
  /** SEO-данные конкретной страницы */
  seoMetaData?: SeoMetaData;
}

/** @example "html" */
export enum ContentPageType {
  Html = "html",
  Text = "text",
}

export interface MobileAppDescription {
  /**
   * Версия операционной системы
   * @example "Android 12"
   */
  platform: string;
  /**
   * Версия приложения
   * @example "2.1.0"
   */
  version?: string;
  /**
   * Номер сборки
   * @example "Build/SP1A.210812.016"
   */
  build?: string;
}

export interface JWTPayloadSchema {
  /**
   * Дата и время когда истечет время жизни токена в UnixTime
   * @example 1701243426
   */
  exp: number;
  /**
   * Уникальный идентификатор токена. Не изменяется при пролонгации токена
   * @example "018c1a10-b060-7187-9637-8b64639b9d88"
   */
  jti: string;
  /**
   * Идентификатор склада (магазина). С которого, например, будет осуществляться отгрузка заказа
   * @example "e835b0ba-8e8e-11ee-b3ad-c70eee3f7063"
   */
  sti?: string;
  /**
   * Идентификатор авторизованного пользователя (для гостя данного поля нет)
   * @example "f57066ee-8e8e-11ee-8338-c7ed57d7ede6"
   */
  sub?: string;
  /**
   * Номер телефона пользователя
   * @example "8-903-123-4567"
   */
  tel?: string;
  /** Ключ мобильного устройства */
  dvc?: string;
  /**
   * Идентификатор населенного пункта пользователя
   * @example "40c6fe46-8e8f-11ee-85d5-67ad9365efac"
   */
  loc?: string;
  /**
   * Код-идентификатор выбранного языка интерфейса
   * @example "ru"
   */
  lng?: string;
}

export interface ProductsDataRequest {
  /**
   * Идентификатор продукта
   * @example "38ee28f4-81ef-11ee-8a48-b70bb8afb523"
   */
  id: string;
  /**
   * Количество
   * @example 1
   */
  quantity: number;
}

export interface GiftSetProductRequest {
  /**
   * Идентификатор продукта
   * @example "0189f37c-091b-717b-8ace-dafd2cf8c977"
   */
  id: string;
}

export interface OrderCreateRequest {
  customer: OrderCustomer;
  bonuses?: OrderBonusesRequest;
  deliveryData: OrderCourierDeliveryData | OrderPickupDeliveryData;
  /**
   * Список товаров в заказе
   * @minItems 1
   */
  products?: ProductsDataRequest[];
  /** Комментарий */
  comment?: string;
  /**
   * Идентификатор способа оплаты
   * @example "d8fbe95e-1a84-4675-9942-b5eeab497d19"
   */
  paymentId: string;
}

export interface OrderCustomer {
  /** Пол */
  gender?: Gender;
  /** Имя пользователя */
  firstname: string;
  /** Фамилия пользователя */
  lastname: string;
  /** Почта */
  email?: string;
  /** Телефон */
  phone: string;
}

export interface OrderBonusesRequest {
  /**
   * Использовать бонусы
   * @example true
   */
  use: boolean;
  /**
   * Количество бонусов
   * @example 140
   */
  amount: number;
}

export type  OrderCourierDeliveryData = OrderDeliveryDataBase & {
  address?: OrderAddress | OrderAddressId;
};

export type OrderPickupDeliveryData = OrderDeliveryDataBase & {
  /**
   * Идентификатор пункта самовывоза
   * @example "fbea2ab8-98b7-11ee-a87d-03492824e220"
   */
  pickupPointId?: string;
};

export interface OrderDeliveryDataBase {
  /**
   * Идентификатор способа доставки
   * @example "a5cc97aa-dab7-11ee-a7b2-9374056f11bf"
   */
  deliveryId: string;
}

export type ProfileOrderPickupDeliveryData = ProfileOrderDeliveryDataBase & {
  storePeriod?: string;
};

export interface ProfileOrderDeliveryDataBase {
  address: string;
  deliveryInterval?: string;
  deliveryId: string;
}

export interface PasswordChangeDataRequest {
  /** Новый пароль */
  newPassword: string;
}

export interface PasswordRestoreDataRequest {
  /** Логин или eMail через который необходимо восстановить пароль */
  login: string;
  /** Новый пароль */
  password?: string;
  /** Контрольный код высланный на eMail */
  code?: string;
}

export interface RegistrationByLoginDataRequest {
  /** Логин (или емалй) */
  login: string;
  /** Пароль */
  password: string;
  /** Фамилия пользователя */
  lastname?: string;
  /** Имя пользователя */
  firstname?: string;
  /** Телефон пользователя */
  phone?: string;
}

export interface AuthByPhoneDataRequest {
  /** Подтвержденный номер телефона */
  phone: string;
  /** Код подтверждения */
  code?: string;
}

export interface ProfilePhoneDataRequest {
  /** Номер телефона */
  phone: string;
  /** Код подтверждения */
  code?: string;
}

export interface ProfileEmailDataRequest {
  /** Адрес электронной почты */
  email: string;
  /** Код подтверждения */
  code?: string;
}

export interface AuthByLoginDataRequest {
  /** Логин (или eMail) пользователя */
  login: string;
  /** Пароль пользователя */
  password: string;
}

export interface AuthByEmailDataRequest {
  /** Email пользователя */
  email: string;
  /** Код подтверждения */
  code?: string;
}

export interface AuthBySocialDataRequest {
  /** Код провайдера авторизации */
  provider: string;
  /** Код, используемый для получения токена доступа. Обязателен, если не указан токен доступа */
  code?: string;
  /** Токен доступа, обязателен, если не указан код для получения токена */
  accessToken?: string;
}

/** Запрос для обновления профиля пользователя */
export interface ProfileUpdateDataRequest {
  /** Фамилия пользователя */
  lastname?: string;
  /** Имя пользователя */
  firstname?: string;
  /** Отчество пользователя */
  patronymic?: string;
  /** Пол */
  gender?: Gender;
  /**
   * Дата рождения (формат: ISO-8601 = DATE_ATOM)
   * @format date
   */
  birthday?: string;
  /** EMail пользователя */
  email?: string;
  /** Телефон пользователя */
  phone?: string;
  /** Пароль пользователя */
  password?: string;
  /** Подписки */
  subscriptions?: Subscriptions;
}

export interface ProfileDeleteDataRequest {
  /**
   * Код, полученный в СМС сообщении или по электронной почте
   * @example "1234"
   */
  code: string;
}

/** Запрос для создания адреса доставки */
export type DeliveryAddressCreateDataRequest = UtilRequiredKeys<
  DeliveryAddressBase,
  "address" | "isDefault" | "isPrivateHouse"
>;

/** Запрос для обновления адреса доставки */
export type DeliveryAddressUpdateDataRequest = DeliveryAddressBase;

export interface BasketUpdateDataRequest {
  /** @minItems 1 */
  products: PatchBasketRequestData[];
}

export interface BasketGiftsAddDataRequest {
  /** @minItems 1 */
  products: GiftSetProductRequest[];
}

export interface BasketClearDataRequest {
  /**
   * Операция над корзиной
   * @example "erase"
   */
  operation: string;
}

export interface LocationChangeDataRequest {
  /**
   * Идентификатор населенного пункта
   * @example "0189f37c-091b-717b-8ace-dafd2cf8c977"
   */
  locationId?: string;
}

export interface SubscribeDataRequest {
  /**
   * E-mail, на который осуществляется подписка
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * Признак принятия пользовательского соглашения
   * @example true
   */
  agreementConfirmed: boolean;
}

export interface SubscribeConfirmDataRequest {
  /**
   * E-mail, на который осуществляется подписка
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * Хэш для подтверждения подписки на рассылку
   * @example "2ef7bde608ce5704e97d5f042f95f89f1c232871"
   */
  hash: string;
}

export interface FavouritesCreateDataRequest {
  /**
   * Идентификатор товара
   * @example "123"
   */
  productId: string;
}

/** Запрос для создания нового обращения */
export type FeedbackCreateDataRequest = UtilRequiredKeys<Feedback, "subjectId" | "name" | "email" | "message"> & {
  /**
   * Признак принятия пользовательского соглашения
   * @example true
   */
  agreementConfirmed: boolean;
};

export type FranchiseCreateDataRequest = Franchise;

export interface LanguageChangeDataRequest {
  /**
   * Код языка
   * @example "en"
   */
  languageId?: string;
}

/** Запрос на отмену заказа */
export interface OrderCancelDataRequest {
  /**
   * Причина отмены заказа
   * @example "Передумал покупать"
   */
  cancelReason?: string;
}

/** Запрос на применение промокода к корзине */
export interface PromocodeActionDataRequest {
  /**
   * Код промокода
   * @example "1234"
   */
  promoCode: string;
}

export interface RegistrationByLoginDataResponse {
  /** Профиль пользователя */
  profile?: Profile;
}

export interface SocialConnectsDataResponse {
  socialConnects: SocialConnect[];
}

export interface ProfileDataResponse {
  /** Профиль пользователя */
  profile: Profile;
}

export interface DeliveryAddressesDataResponse {
  /**
   * Список адресов доставки
   * @minItems 0
   */
  deliveryAddresses: DeliveryAddress[];
}

export interface DeliveryAddressCreateDataResponse {
  /** Адрес доставки */
  deliveryAddress: DeliveryAddress;
}

export interface DeliveryAddressUpdateDataResponse {
  /** Адрес доставки */
  deliveryAddress: DeliveryAddress;
}

export interface CategoriesDataResponse {
  categories: Category[];
}

/**
 * Ограничения при совершении заказа. Могут меняться на каждом проекте.
 * @export
 * @interface BasketDataResponseLimits
 */
export interface BasketDataResponseLimits {
  /**
   * Минимальная стоимость заказа (значение умноженное на 100 - в копейках)
   * @type {number}
   * @memberof BasketDataResponseLimits
   */
  minCost?: number;
}

/**
 * Одни промокод, примененный к корзине
 * @export
 * @interface BasketDataResponsePromocodes
 */
export interface BasketDataResponsePromocodes {
  /**
   * Код промокода
   * @type {string}
   * @memberof BasketDataResponsePromocodes
   */
  promoCode?: string;
  /**
   * Дата окончания действия промокода
   * @type {Date}
   * @memberof BasketDataResponsePromocodes
   */
  expiredDate?: Date;
}

/**
 * Данные о бонусах пользователя, использованных в корзине
 * @export
 * @interface BasketDataResponseBonuses
 */
export interface BasketDataResponseBonuses {
  /**
   * Сколько бонусов всего
   * @type {number}
   * @memberof BasketDataResponseBonuses
   */
  total: number;
  /**
   * Кол-во использованных бонусов в текущей корзине
   * @type {number}
   * @memberof BasketDataResponseBonuses
   */
  spent: number;
  /**
   * Максимальное количество бонусов, которое можно использовать в текущей корзине
   * @type {number}
   * @memberof BasketDataResponseBonuses
   */
  maxSpent: number;
  /**
   * Сколько бонусов будет получено после завершения покупки
   * @type {number}
   * @memberof BasketDataResponseBonuses
   */
  earned?: number;
}

/**
 *
 * @export
 * @interface BasketDataResponse
 */
export interface BasketDataResponse {
  /**
   *
   * @type {BasketTotal}
   * @memberof BasketDataResponse
   */
  total: BasketTotal;
  /**
   *
   * @type {BasketDataResponseLimits}
   * @memberof BasketDataResponse
   */
  limits?: BasketDataResponseLimits;
  /**
   * Товары в корзине и их количество
   * @type {Array<BasketItem>}
   * @memberof BasketDataResponse
   */
  products: Array<BasketItem>;
  /**
   * Промокоды, примененные к корзине
   * @type {Array<BasketDataResponsePromocodes>}
   * @memberof BasketDataResponse
   */
  promocodes?: Array<BasketDataResponsePromocodes>;
  /**
   *
   * @type {BasketDataResponseBonuses}
   * @memberof BasketDataResponse
   */
  bonuses?: BasketDataResponseBonuses;
}

export interface BasketGiftsDataResponse {
  /** @minItems 0 */
  products?: ProductGift[];
}

export interface LocationsDataResponse {
  /** @minItems 1 */
  locations: Location[];
}

export interface AnnouncementsDataResponse {
  /**
   * Список объявлений
   * @minItems 0
   */
  announcements: Announcement[];
}

export interface BrandsDataResponse {
  /** Список брендов */
  brands: BrandBase[];
}

export interface BrandsTopDataResponse {
  /** Список популярных брендов */
  brands: BrandTop[];
  /** Данные по навигации */
  page?: PageInfo;
}

export interface BrandXDataResponse {
  brand: BrandDetail;
}

/**
 * Тип категории (обычная, визуальная, техническая и т.д.). В каждом проекте может быть свой набор типов
 * @example "simple"
 */
export enum CategoryTypeEnum {
  Simple = "simple",
  Collection = "collection",
  Mixed = "mixed",
  Technical = "technical",
  Visual = "visual",
}

export interface CategoryBase {
  /**
   * Идентификатор категории
   * @example 1003001
   */
  id: string;
  /**
   * Код категории товаров на транслите (например - obuv, sorochki)
   * @example "sorochki"
   */
  code: string;
  /** Тип категории (обычная, визуальная, техническая и т.д.). В каждом проекте может быть свой набор типов */
  type?: CategoryTypeEnum;
  /**
   * Наименование категории товаров
   * @example "Сорочки"
   */
  title: string;
  /**
   * Цвет заголовка
   * @example "#ba1051"
   */
  titleColor?: string;
  icon?: MediaGroupIcon;
  image?: MediaGroupImage;
}

export interface StoreBase {
  /**
   * Идентификатор магазина
   * @example "07ba5980-ed8b-11ee-9fb8-af12dbefa2c2"
   */
  id: string;
  /**
   * Код магазина
   * @example "store-msk-moskovskiy"
   */
  code?: string;
  /**
   * Название магазина
   * @example "Магазин на Московском пр-те"
   */
  title: string;
  /**
   * Адрес магазина
   * @example "Московский проспект, 45 – ТЦ «Оазис»"
   */
  address?: string;
  /**
   * Телефоны магазина
   * @example "+7 (999) 999-99-99 доб. 1038"
   */
  phones?: string;
  /**
   * Широта
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example 54.695829999995
   */
  latitude?: string;
  /**
   * Долгота
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example 20.525669
   */
  longitude?: string;
  /**
   * График работы магазина (дни и часы)
   * @minItems 0
   */
  schedule?: WorkingHours[];
  /** Ближайшие станции метро */
  metroStations?: MetroStation[];
  logo?: MediaGroupVisual;
  /** Фотографии магазина */
  media?: MediaGroupImage[];
}

export interface SearchResult {
  /** Список акций попадающих под условие поиска */
  actions?: ActionBase[];
  /** Список брендов попадающих под условие поиска */
  brands?: BrandBase[];
  /** Список категорий попадающих под условие поиска */
  categories?: CategoryBase[];
  /** Список коллекций попадающих под условие поиска */
  collections?: CollectionBase[];
  /** Список магазинов попадающих под условие поиска */
  stores?: StoreBase[];
  /** Список услуг попадающих под условие поиска */
  services?: ServiceBase[];
  /** Список товаров попадающих под условие поиска */
  products: ProductBase[];
}

export type SearchSuggestDataResponse = UtilRequiredKeys<SearchResult, "products"> & {
  /** Список часто используемых поисковых фраз попадающих под условие поиска */
  suggestions?: string[];
  /** Строка для перенаправления пользователя */
  redirect?: string;
  /** Рекомендованные продукты, прилетают в пустом запросе */
  productsHits?: ProductBase[]
};

export interface SearchResultDataResponse {
  /** Поисковый запрос для которого был сформирован ответ */
  found?: string;
  /** @minItems 0 */
  categories?: CategoryShort[];
  /**
   * Список товаров, удовлетворяющий поисковому запросу
   * @minItems 0
   */
  products: ProductShort[];
  /** Строка для перенаправления пользователя */
  redirect?: string;
  filters?: FilterInfo;
  sorts?: SortInfo;
  /** Данные по навигации */
  page?: PageInfo;
}

export interface ArticlesDataResponse {
  /** Список статей, соответствующих условиям фильтрации */
  articles: ArticleShort[];
  filters: FilterInfo;
  /** Данные по навигации */
  page: PageInfo;
}

export interface ArticleTypesDataResponse {
  /** Список типов статей */
  articleTypes: ArticleType[];
}

export interface ProductsDataResponse {
  /**
   * Список товаров, попадающих под условия фильтрации
   * @minItems 0
   */
  products: ProductShort[];
  filters: FilterInfo;
  /** Список комбо-фильтров */
  combo?: ComboInfo;
  sorts: SortInfo;
  /** Данные по навигации */
  page: PageInfo;
}

export interface ProductsViewedDataResponse {
  /** @minItems 0 */
  viewedProducts: ProductShort[];
}

export interface ProductsRecommendedDataResponse {
  /** @minItems 0 */
  recommendedProducts: ProductShort[];
}

export interface ProductsNewestDataResponse {
  /** @minItems 0 */
  newestProducts: ProductShort[];
}

export interface ProductsBestsellerDataResponse {
  /** @minItems 0 */
  bestsellerProducts: ProductShort[];
}

export interface ProductsGroupsDataResponse {
  /** @minItems 0 */
  groups: {
    /** Тип медиадокумента */
    code?: MediaTypeEnum;
    /** @example "Новинки недели" */
    title?: string;
    /** Ссылка на раздел системы */
    link?: Link;
    products?: ProductShort[];
    /** Данные по навигации */
    page?: PageInfo;
  }[];
}

export interface ProductXDataResponse {
  product: ProductDetail;
}

export interface ProductsRelatedDataResponse {
  /** Список сопутствующих товаров */
  relatedProducts: ProductShort[];
}

export interface ProductsSimilarDataResponse {
  /** Список подобных товаров */
  similarProducts: ProductShort[];
}

export interface StoresDataResponse {
  /**
   * Список магазинов, попадающих под условия фильтрации
   * @minItems 0
   */
  stores: StoreShort[];
  filters: FilterInfo;
}

export interface StoreXDataResponse {
  store: StoreDetail;
}

export interface PromosDataResponse {
  /**
   * Список банеров
   * @minItems 0
   */
  promos: Promo[];
}

export interface LanguagesDataResponse {
  /** @minItems 1 */
  languages: Language[];
}

export interface FavouritesDataResponse {
  /**
   * Список товаров в избранном пользователя
   * @minItems 0
   */
  favourites: ProductShort[];
  filters?: FilterInfo;
  sorts?: SortInfo;
  /** Данные по навигации */
  page?: PageInfo;
}

export interface RecommendationsDataResponse {
  /**
   * Список типов рекомендованных товаров
   * @minItems 0
   */
  recommendationTypes: RecommendationTypeDetail[];
  /**
   * Список товаров, участвующих в рекомендации
   * @minItems 0
   */
  products: ProductShort[];
  /** Данные по навигации */
  page?: PageInfo;
}

export interface CategoriesTopDataResponse {
  /** @minItems 1 */
  categories: CategoryShort[];
  /** Данные по навигации */
  page?: PageInfo;
}

export interface FeedbackSubjectsDataResponse {
  subjects: FeedbackSubject[];
}

export interface FeedbackCreateDataResponse {
  /** Обращение в службу поддержки или обратная связь */
  feedback: Feedback;
}

export interface MenusDataResponse {
  /**
   * Список меню
   * @minItems 0
   */
  menus: Menu[];
}

export interface FaqDataResponse {
  /**
   * Список групп вопросов
   * @minItems 0
   */
  groups: FaqGroup[];
  /**
   * Список вопросов и ответов
   * @minItems 0
   */
  faqs: Faq[];
}

export interface CollectionsDataResponse {
  /** Список коллекций */
  collections: CollectionShort[];
  filters?: FilterInfo;
  sorts?: SortInfo;
  /** Данные по навигации */
  page?: PageInfo;
}

export interface CollectionXDataResponse {
  collection: CollectionDetail;
}

export interface CollectionsTopDataResponse {
  /** Список популярных коллекций */
  collections: CollectionTop[];
  /** Данные по навигации */
  page?: PageInfo;
}

export interface ActionsDataResponse {
  /** Список акций */
  actions: ActionShort[];
  filters?: FilterInfo;
  sorts?: SortInfo;
  /** Данные по навигации */
  page?: PageInfo;
}

export interface ActionXDataResponse {
  action: ActionDetail;
}

export interface ActionsTopDataResponse {
  /** Список популярных акций */
  actions: ActionTop[];
  /** Данные по навигации */
  page?: PageInfo;
}

export interface ServicesDataResponse {
  /** Список услуг */
  services: ServiceShort[];
  filters?: FilterInfo;
  sorts?: SortInfo;
  /** Данные по навигации */
  page?: PageInfo;
}

export interface ServiceXDataResponse {
  service: ServiceDetail;
}

export interface ServicesTopDataResponse {
  /** Список популярных услуг */
  services: ServiceTop[];
  /** Данные по навигации */
  page?: PageInfo;
}

export interface OrdersDataResponse {
  /** Список заказов */
  orders: OrderShort[];
  filters?: FilterInfo;
  sorts?: SortInfo;
  /** Данные по навигации */
  page?: PageInfo;
}

export interface OrderXDataResponse {
  order: OrderDetail;
}

export interface DeliveryInfoDataResponse {
  deliveries?: DeliveryInfo[];
}

/** Профиль пользователя */
export interface Profile {
  /**
   * Идентификатор пользователя
   * @example "86d36ec2-f570-11ee-9aab-7b2cf0a23872"
   */
  id: string;
  /**
   * Фамилия пользователя
   * @example "Иванов"
   */
  lastname?: string | null;
  /**
   * Имя пользователя
   * @example "Иван"
   */
  firstname?: string | null;
  /**
   * Отчество пользователя
   * @example "Иванович"
   */
  patronymic?: string | null;
  /** Пол */
  gender?: Gender;
  /**
   * Дата рождения (формат: ISO-8601 = DATE_ATOM)
   * @format date
   * @example "2001-09-27T00:00:00.000Z"
   */
  birthday?: string | null;
  /**
   * Телефон пользователя
   * @example "8-903-123-4567"
   */
  phone?: string | null;
  /**
   * EMail пользователя
   * @example "mail@example.org"
   */
  email?: string | null;
  /**
   * Подтвержден ли номер телефона пользователя?
   * @example true
   */
  isPhoneVerified: boolean;
  /**
   * Подтвержден ли email пользователя?
   * @example false
   */
  isEmailVerified: boolean;
  /** Подписки */
  inn?: string
  kpp?: string
  legalAddress?: string
  legalName?: string
  postAddress?: string
  isLegal: boolean
  userGroups: string[]
  subscriptions?: Subscriptions;
  password?: string;
}

export interface SocialConnect {
  /**
   * Код провайдера авторизации
   * @example "vkontakte"
   */
  provider: string;
  /**
   * Наименование провайдера авторизации
   * @example "ВКонтакте"
   */
  title: string;
  /**
   * Идентификатор клиента в социальной сети
   * @example "8213369"
   */
  clientId: string;
  /**
   * URL авторизации в системе
   * @example "https://oauth.vk.com/authorize?client_id=8213369&redirect_uri=http%3A%2F%2Fuserstory.ru%2Foauth-vkontakte?scope=friends,offline,email&response_type=code&state=site_id%3Ds1%26backurl%3D%252Fapi%252Fv2%252Fsocial-connects%253Fcheck_key%253Dce7c79d8344654dc3d9fc275bf92a78e%2526routePath%253D%25252Fsocial-connects"
   */
  authUrl: string;
  /** Список запрашиваемых данных */
  scope: string[];
  /**
   * Обратный URL на который возвращается пользователь
   * @example "https://userstory.ru/oauth-vkontakte"
   */
  redirectUri: string;
}

/** Адрес доставки (базовая часть) */
export interface DeliveryAddressBase {
  /**
   * Название адреса
   * @example "Работа"
   */
  name?: string;
  /**
   * Адрес доставки
   * @example "г.Томск, ул.Красноармейская, д.128, кв.120"
   */
  address?: string;
  /**
   * Широта географической координаты
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "56.458925"
   */
  latitude?: string;
  /**
   * Долгота географической координаты
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "84.973992"
   */
  longitude?: string;
  /**
   * Признак, что адрес используется по умолчанию
   * @example true
   */
  isDefault?: boolean;
  /**
   * Флаг частного дома
   * @example false
   */
  isPrivateHouse?: boolean;
  /**
   * Подъезд
   * @example 3
   */
  entrance?: number | null;
  /**
   * Этаж
   * @example 6
   */
  floor?: number | null;
  /** Квартира */
  apartment?: string | null;
  /** Почтовый индекс */
  zip?: string | null;
  /** Домофон */
  doorcode?: string | null;
  /**
   * Комментарий
   * @example "Подъезд со стороны улицы. Вторая дверь (синяя)"
   */
  comment?: string | null;
}

/** Адрес доставки */
export type DeliveryAddress = UtilRequiredKeys<DeliveryAddressBase, "address" | "isDefault"> & {
  /** Идентификатор адреса доставки */
  id: string;
};

/**
 * Тип категории (обычная, визуальная, техническая и т.д.). В каждом проекте может быть свой набор типов
 * @example "simple"
 */
export enum CategoryType {
  Simple = "simple",
  Collection = "collection",
  Mixed = "mixed",
  Technical = "technical",
  Visual = "visual",
}

export interface CategoryShort {
  /**
   * Идентификатор категории
   * @example "1003001"
   */
  id: string;
  /**
   * Код категории товаров на транслите (например: obuv, sorochki)
   * @example "sorochki"
   */
  code: string;
  /** Тип категории (обычная, визуальная, техническая и т.д.). В каждом проекте может быть свой набор типов */
  type?: CategoryType;
  /**
   * Наименование категории товаров
   * @example "Сорочки"
   */
  title: string;
  /**
   * Цвет заголовка
   * @example "#ba1051"
   */
  titleColor?: string;
  /**
   * Кол-во продуктов в категории
   * @example 125
   */
  productCount?: number | null;
  icon?: MediaGroupIcon;
  image?: MediaGroupImage;
  /** Общий объект для данных категорий, расширяемых на конкретном проекте */
  extra?: CategoryExtraData;
}

export type Category = CategoryShort & {
  /** Идентификатор основной родительской категории */
  parentId: string | null;
  /** Идентификаторы всех родительских категорий */
  parentIdList: string[];
  /** SEO-данные конкретной страницы */
  seoMetaData?: SeoMetaData;
};

export interface Sorting {
  /**
   * Признак по которому осуществляется сортировка
   * @example "rating-desc"
   */
  direction: "rating-desc" | "price-asc" | "price-desc" | "name-asc" | "name-desc";
  /**
   * Наименование сортировки
   * @example "По рейтингу"
   */
  title: string;
  /**
   * Комментарий к способу сортировки
   * @example "Сначала чаще покупаемые"
   */
  comment?: string;
  /** В текущей выборке использовался именно этот признак сортировки? */
  isApplied: boolean;
}

export type SortInfo = Sorting[];

/** Данные по навигации */
export interface PageInfo {
  /**
   * Номер текущей страницы, отображаемой в выборке
   * @min 1
   * @example 1
   */
  page: number;
  /**
   * Максимальное количество элементов на странице (ограничение, используемое для текущей выборки)
   * @min 1
   * @example 12
   */
  limit: number;
  /**
   * Общее количество элементов, попадающих под условия фильтрации
   * @example 32
   */
  count: number;
}

/**
 * Тип фильтра (выбор из предложенного, переключатель, диапазон, диапазон дат)
 * @example "choose"
 */
export enum FilterType {
  Choose = "choose",
  Flag = "flag",
  Range = "range",
  DateRange = "date-range",
  Store = "store",
  Quantity = 'quantity'
}

export interface BaseFilter {
  /**
   * Код фильтра = код свойства товаров
   * @example "brand"
   */
  code: string;
  /** Тип фильтра (выбор из предложенного, переключатель, диапазон, диапазон дат) */
  type: FilterType;
  /**
   * Наименование фильтра
   * @example "Бренд"
   */
  title: string;
  /** Подсказка к фильтру */
  hint?: string | null;
}

export type FilterStore = BaseFilter & {
  /** @example "store" */
  type?: any;
  /**
   * Флаг, символизирующий допустимость множественного выбора магазинов
   * @example false
   */
  isMultiple?: boolean;
  /**
   * Список доступных магазинов (или ранее выбранных)
   * @minItems 0
   */
  variants?: StoreVariant[];
};

export interface CompareCreateDataRequest {
  /**
   * Идентификатор товара
   * @example "d92bfc8c-020b-11ef-b0a0-0751c2b65bbd"
   */
  productId: string;
}

export type StoreVariant = StoreShort & {
  /**
   * Вариант фильтрации был использован для текущей выборки
   * @example true
   */
  isApplied: boolean;
};

export type FilterChoose = BaseFilter & {
  /** @default "choose" */
  type?: any;
  /**
   * Флаг, символизирующий допустимость множественного выбора
   * @example true
   */
  isMultiple: boolean;
  /**
   * Свернут ли фильтр по умолчанию
   * @example false
   */
  isCollapsed: boolean;
  variants: ChooseVariant[];
};

export interface ChooseVariant {
  /**
   * Код варианта выбора
   * @example "apple"
   */
  code: string;
  /**
   * Наименование варианта выбора
   * @example "Apple"
   */
  title: string;
  /** Вариант фильтрации доступен для выбора */
  isEnabled: boolean;
  /** Вариант фильтрации был использован для текущей выборки */
  isApplied: boolean;
  /**
   * Количество элементов, соответствующих значению
   * @example 12
   */
  count: number;
  image?: MediaGroupIcon;
}

export type FilterFlag = BaseFilter & {
  /** @example "available" */
  code?: any;
  /**
   * @default "flag"
   * @example "flag"
   */
  type?: any;
  /** Фильтр использовался для текущей выборки */
  isApplied: boolean;
};

export type FilterRange = BaseFilter & {
  /** @example "price" */
  code?: any;
  /**
   * @default "range"
   * @example "range"
   */
  type?: any;
  /**
   * Минимальное допустимое значение
   * @example 1000
   */
  minRange?: number;
  /**
   * Максимальное допустимое значение
   * @example 150000
   */
  maxRange?: number;
  /**
   * Минимальное значение, указанное пользователем
   * @example 5000
   */
  minValue?: number | null;
  /**
   * Максимальное значение, указанное пользователем
   * @example 30000
   */
  maxValue?: number | null;
  /**
   * Рекомендуемый шаг изменения
   * @example 1
   */
  step?: number;
  /**
   * Единица измерения
   * @example "RUB"
   */
  unit?: string;
};

export type FilterDateRange = BaseFilter & {
  /** @example "delivery" */
  code?: any;
  /**
   * @default "date-range"
   * @example "date-range"
   */
  type?: any;
  /**
   * Минимальное допустимое значение даты
   * @format date
   * @example "2022-01-01"
   */
  minRange?: string;
  /**
   * Максимальное допустимое значение даты
   * @format date
   * @example "2023-12-31"
   */
  maxRange?: string;
  /**
   * Минимальное значение, указанное пользователем
   * @format date
   * @example "2022-02-01"
   */
  minValue?: string | null;
  /**
   * Максимальное значение, указанное пользователем
   * @format date
   * @example "2023-07-01"
   */
  maxValue?: string | null;
};

export type FilterInfo = (
  | FilterStore
  | FilterChoose
  | FilterFlag
  | FilterRange
  | FilterDateRange
  | (FilterStore & FilterChoose & FilterFlag & FilterRange & FilterDateRange)
)[];

export interface ComboFilter {
  /** Наименование комбо-фильтра */
  title: string;
  /**
   * Список применяемых фильтров
   * @minItems 1
   */
  filters: ComboFilterItem[];
  /** Часть URL (если указан - добавлять в URL) */
  urlPart?: string;
}

export interface ComboFilterItem {
  /**
   * Код фильтра = характеристика товара (см. BaseFilter.code)
   * @example "brand"
   */
  code: string;
  /**
   * Код выбранного свойства (см. ChooseVariant.code). Допускается несколько кодов, разделенных запятыми. Для диапазонов: значения диапазонов, разделенных запятой.
   * @example "adidas"
   */
  value: string;
}

/** Список комбо-фильтров */
export type ComboInfo = ComboFilter[];

export interface ProductBase {
  /**
   * Идентификатор товара
   * @example "fbea2ab8-98b7-11ee-a87d-03492824e220"
   */
  id: string;
  /**
   * Наименование товара транслитом
   * @example "142-noutbuk-apple-macbook-pro-serebristyj"
   */
  code: string;
  /**
   * Тип товара (обычный, визуальный, технический и т.п.). Не имеет фиксированного набора значений.
   * @example "simple"
   */
  type: string;
  /**
   * Наименование товара
   * @example "14.2" Ноутбук Apple MacBook Pro серебристый"
   */
  title: string;
  /**
   * Артикул товара
   * @example "5098422"
   */
  article: string;
  /**
   * Средний рейтинг (оценка) товара
   * @example 4.7
   */
  rating?: number;
  /**
   * Количество отзывов
   * @example 10
   */
  reviewCount?: number;
  /**
   * Доступность товара для заказа
   * @example true
   */
  availability?: boolean;
  /**
   * Кол-во единиц товара, доступных к заказу
   * @example 105
   */
  availabilityCount?: number;
  /**
   * Список изображений и видео товара
   * @minItems 0
   */
  media?: MediaGroupVisual[];
  /** Детализация стоимости товара */
  price: ProductPrice;
  /** Идентификатор основной категории товара */
  categoryId: string;
  /** Список идентификаторов всех категорий товара */
  categoryIdList: string[];
  /**
   * Шильдики товара
   * @minItems 0
   */
  nameplates: Nameplate[];
  /**
   * Краткое описание товара
   * @example "Удобные кроссовки Kappa Selecto станут оптимальным вариантом для мужчин, которые предпочитают практичный спортивный стиль.
   * "
   */
  shortDescription?: string;
}

export interface ProductGift {
  /**
   * Цена (значение умноженное на 100 - в копейках)
   * @example 119900
   */
  minBasketPrice?: number;
  product?: ProductShort;
}

export type ProductShort = ProductBase & {
  /**
   * Основные свойства товара
   * @minItems 0
   */
  properties?: ProductProperty[];
};

export type ProductDetail = ProductBase & {
  /**
   * Описание товара
   * @example "Удобные кроссовки Kappa Selecto станут оптимальным вариантом для мужчин, которые предпочитают практичный спортивный стиль.
   * "
   */
  description?: string;
  /**
   * Документы к товару (сертификаты, инструкции и прочее)
   * @minItems 0
   */
  files?: MediaFile[];
  /**
   * Свойства товара
   * @minItems 0
   */
  properties?: ProductProperty[];
  /**
   * Товарные предложения
   * @minItems 0
   */
  variants?: ProductVariant[];
  /** SEO-данные конкретной страницы */
  seoMetaData?: SeoMetaData;
  /**
   * Список всех доступных значений свойств вариантов товара (торговых предложений)
   * @minItems 0
   */
  variantOptions?: ProductVariantOption[];
  collections?: CollectionShort[];
};

/** Список вариантов товара (торговых предложений) */
export interface ProductVariant {
  /**
   * Идентификатор товара
   * @example "577087"
   */
  id: string;
  /**
   * Наименование товара транслитом
   * @example "142-noutbuk-apple-macbook-pro-serebristyj"
   */
  code: string;
  /**
   * Наименование товара
   * @example "14.2" Ноутбук Apple MacBook Pro серебристый"
   */
  title: string;
  /**
   * Список значений свойств варианта товара (торгового предложения)
   * @minItems 0
   */
  variantOptionValues: ProductVariantOptionValue[];
  /** Относится к текущему товару */
  isCurrent?: boolean;
}

export interface ProductProperty {
  /**
   * Идентификатор свойства
   * @example "0189bf14-906e-72a7-9fcf-dc3d5a23267c"
   */
  id: string;
  /**
   * Код свойства
   * @example "impregnation"
   */
  code: string;
  /**
   * Наименование свойства
   * @example "Антибактериальная пропитка"
   */
  title: string;
  /**
   * Наименование группы свойств
   * @example "Общие характеристики"
   */
  group?: string;
  /**
   * Тип свойства
   * @example "boolean"
   */
  type: "string" | "integer" | "list" | "boolean" | "number" | "date" | "date-time";
  value:
    | PropertyStringValue
    | PropertyIntValue
    | PropertyBooleanValue
    | PropertyNumberValue
    | PropertyDateValue
    | PropertyDateTimeValue
    | PropertyListValue;
}

/** Строковое значение характеристики */
export type PropertyStringValue = string;

/** Числовое значение характеристики */
export type PropertyIntValue = number;

/** Логическое значение характеристики */
export type PropertyBooleanValue = boolean;

/** Значение характеристики в виде числа с плавающей запятой */
export type PropertyNumberValue = number;

/**
 * Строковое значение характеристики в формате даты
 * @format date
 */
export type PropertyDateValue = string;

/**
 * Строковое значение характеристики в формате даты/времени
 * @format date-time
 */
export type PropertyDateTimeValue = string;

/**
 * Значения характеристики в виде списка
 * @minItems 1
 */
export type PropertyListValue = PropertyListItemValue[];

export interface PropertyListItemValue {
  /** Код варианта значения характеристики */
  code: string;
  /** Наименование варианта значения характеристики */
  title: string;
}

/**
 * Единица измерения товара
 * @example "шт"
 */
export type Unit = string;

/** Стоимость товара */
export interface BasePrice {
  /**
   * Цена (значение умноженное на 100 - в копейках)
   * @example 119900
   */
  price: number;
  /**
   * Валюта стоимости (RUB)
   * @example "RUB"
   */
  currency: "RUB";
}

/** Стоимость товара за указанную единицу измерения */
export type Price = BasePrice & {
  /** Единица измерения товара */
  unit?: Unit;
};

/** Детализация стоимости товара */
export interface ProductPrice {
  /** Стоимость товара */
  base?: BasePrice;
  /** Стоимость товара за указанную единицу измерения */
  main: Price;
  /** Стоимость товара */
  baseDiscount?: BasePrice;
  /** Стоимость товара за указанную единицу измерения */
  mainDiscount?: Price;
  /**
   * Стоимость товара в других единицах измерения
   * @minItems 0
   */
  additional?: Price[];
}

/** Шильдики товара */
export interface Nameplate {
  /**
   * Тип шильдика:
   *  * `new` - Новинка
   *  * `action` - Акция
   *  * `bestseller` - Хит продаж
   */
  type: "new" | "action" | "bestseller";
  /**
   * Заголовок шильдика
   * @example "Новинка"
   */
  title: string;
  /**
   * URL акции, новинок
   * @example "/catalog/"
   */
  url?: string | null;
  /**
   * Цвет фона шильдика
   * @example "#FFC72C"
   */
  bgColor: string | null;
  /**
   * Цвет текста шильдика
   * @example "#1C1C1C"
   */
  textColor: string | null;
  icon?: MediaGroupIcon;
}

/** Изменение количества товара в корине */
export interface PatchBasketRequestData {
  /** Идентификатор товара */
  productId: string;
  /**
   * Требуемое количество товара в корзине
   * @example 2
   */
  quantity: number;
  /**
   * Товар в корзине выбран
   * @example true
   */
  isSelected?: boolean;
}

export interface Location {
  /**
   * Идентификатор населенного пункта
   * @example "0189f37c-091b-717b-8ace-dafd2cf8c977"
   */
  id?: string;
  /**
   * Наименование населенного пункта
   * @example "Москва"
   */
  title?: string;
  /**
   * Широта
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "54.695829999995"
   */
  latitude?: string;
  /**
   * Долгота
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "20.525669"
   */
  longitude?: string;
}

export type SetProductBase = ProductBase & {
  products?: {
    /** @example 1 */
    quantity?: number;
    product?: ProductBase;
  }[];
};

export type AssemblyProducts = ProductBase | SetProductBase | (ProductBase & SetProductBase);

export interface CompareDataResponse {
  /**
   * Список товаров пользователя для сравнения
   * @minItems 0
   */
  compareList: AssemblyProducts[];
}


export interface Announcement {
  /**
   * Идентификатор объявления
   * @example "018a20c3-28b0-7345-9325-ecfdd4597cf7"
   */
  id: string;
  /**
   * Заголовок объявления
   * @example "До -40% — финальная летняя распродажа 🔥"
   */
  title: string;
  image?: MediaGroupImage;
  /** Ссылка на детальное описание объявления */
  url?: string;
}

export interface Errors {
  errors?: (CommonError | ValidationError | (CommonError & ValidationError))[];
}

export interface CommonError {
  /** Описание возникшей ошибки */
  message: string;
  /** Код ошибки */
  code: string;
}

export interface BrandBase {
  /**
   * ИД бренда
   * @example "07ba5980-ed8b-11ee-9fb8-af12dbefa2c2"
   */
  id: string;
  /**
   * Код бренда
   * @example "onegin"
   */
  code: string;
  /**
   * Наименование бренда
   * @example "Онегин"
   */
  title: string;
  logo?: MediaGroupVisual;
  /** Общий объект для данных бренда, расширяемых на конкретном проекте */
  extra?: BrandExtraData;
}

export type BrandTop = UtilRequiredKeys<BrandBase, "logo"> & {
  /**
   * Краткий список товаров бренда (например, для отображения на главной)
   * @minItems 0
   */
  products?: ProductShort[];
};

export type BrandDetail = BrandBase & {
  /** Описание бренда */
  description?: string;
  presentation?: MediaGroupVisual[];
  /** SEO-данные конкретной страницы */
  seoMetaData?: SeoMetaData;
};

export interface ArticleType {
  /**
   * Код типа статей
   * @example "news"
   */
  code: string;
  /**
   * Название типа статей
   * @example "Новости"
   */
  title: string;
}

export interface ArticleShort {
  /**
   * Код статьи (заголовок транслитом)
   * @example "otkryvayte-leto-kazhdyy-den-iyunya"
   */
  code: string;
  /**
   * Название статьи
   * @example "Открывайте лето каждый день июня"
   */
  title: string;
  /**
   * Дата публикации
   * @format date-time
   */
  publishDate: string;
  /**
   * Дата обновления статьи
   * @format date-time
   */
  updatedAt?: string;
  image: MediaGroupImage;
  readingTime?: TimeInterval;
  /**
   * Сколько раз статья была прочитана
   * @example 156
   */
  readingCounter?: number;
}

export interface TimeInterval {
  /**
   * Значение времени
   * @example 5
   */
  value: number;
  /**
   * Единица измерения времени
   * @example "minute"
   */
  unit: "second" | "minute" | "hour";
}

export interface ValidationError {
  /** Описание возникшей ошибки */
  message: string;
  /** Код ошибки */
  code: string;
  /** Путь до поля с ошибкой */
  field: string;
}

export interface StoreShort {
  /** Идентификатор магазина */
  id: string;
  /**
   * Название магазина
   * @example "Магазин на Московском пр-те"
   */
  title: string;
  /**
   * Код магазина
   * @example "store-msk-moskovskiy"
   */
  code?: string;
  /**
   * Адрес магазина
   * @example "Московский проспект, 45 – ТЦ «Оазис»"
   */
  address?: string;
  /**
   * Телефоны магазина
   * @example "+7 (999) 999-99-99 доб. 1038"
   */
  phones?: string;
  /**
   * Широта
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "54.695829999995"
   */
  latitude?: string;
  /**
   * Долгота
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "20.525669"
   */
  longitude?: string;
  /**
   * График работы магазина (дни и часы)
   * @minItems 0
   */
  schedule?: WorkingHours[];
  /**
   * Ближайшие станции метро
   * @minItems 0
   */
  metroStations?: MetroStation[];
  /**
   * Фотографии магазина
   * @minItems 0
   */
  images?: MediaGroupImage[];
}

export type StoreDetail = StoreShort & {
  /**
   * Подробное описание магазина
   * @example "От метро Комендантский проспект автобусы 127 и 171; маршрутное такси 191 и 168."
   */
  description?: string;
  /**
   * Как добраться до магазина
   * @example "От метро Комендантский проспект автобусы 127 и 171; маршрутное такси 191 и 168."
   */
  getTo?: string;
  /**
   * Способы оплаты в магазине
   * @minItems 0
   */
  paymentMethods?: PaymentMethodBase[];
  /** SEO-данные конкретной страницы */
  seoMetaData?: SeoMetaData;
};

/**
 * Код банерного места (в каждом проекте может быть свой набор кодов)
 * @example "listing"
 */
export enum PromoPlace {
  Top = "top",
  Menu = "menu",
  Listing = "listing",
  Header = "header",
  HeaderSecond = "header-2",
  catalog = "catalog",
  GiftCard = 'home-gift-card',
  FloristryStudio = 'home-place-2',
  FloristryStudioLeft = 'home-place-2-1',
  FloristryStudioRight = 'home-place-2-2',
  SummerFiesta = 'home-place-1',
  SummerFiestaLeft = 'home-place-1-1',
  SummerFiestaRight = 'home-place-1-2',
  About = 'home-about-1',
  AboutLeft = 'home-about-1-1',
  AboutRight = 'home-about-1-2',
  AboutUs = 'home-about',
  Popular = 'popular',
  New = 'new',
  Bestseller= 'bestseller'
}

/** Банер */
export interface Promo {
  /**
   * Идентификатор банер
   * @example "ba6fc708-6bff-11ee-9d97-835063bd47b3"
   */
  id: string;
  /** Код банерного места (в каждом проекте может быть свой набор кодов) */
  place: PromoPlace;
  /**
   * Название банера
   * @example "Скидка до 80%!"
   */
  title?: string | null;
  /**
   * Описание банера
   * @example "Встречайте новую коллекцию"
   */
  description?: string | null;
  /**
   * Текст копки банера
   * @example "Подробнее"
   */
  buttonMessage?: string | null;
  /**
   * URL-адрес, куда должен перейти пользователь в результате клика по банеру
   * @example "https://example.org/page"
   */
  link: Link;
  media: MediaGroupVisual;
  isLight?: boolean;
  /**
   * Цвет фона банера
   */
  bgColor?: string;
}

/** Часы работы */
export interface WorkingHours {
  /**
   * Дни, в которые такие часы работы
   * @example "Пн-пт"
   */
  days: string;
  /**
   * Часы работы (с)
   * @example "10:00"
   */
  from: string;
  /**
   * Часы работы (до)
   * @example "22:00"
   */
  to: string;
}

/** Станция метро */
export interface MetroStation {
  /**
   * Название станции
   * @example "Пролетарская"
   */
  name: string;
  /**
   * Цвет линии
   * @example "#32CD32"
   */
  lineColor?: string;
  /**
   * Время пешком от станции до магазина
   * @example "15 мин"
   */
  walkingTime?: string;
}

export interface RecommendationTypeDetail {
  /**
   * Код типа рекомендованных товаров
   * @example "bestsellers"
   */
  code: string;
  /**
   * Название типа рекомендованных товаров
   * @example "Хиты продаж"
   */
  title: string;
  /** В текущей выборке используется этот тип рекомендованных товаров? */
  isApplied: boolean;
  /**
   * Описание типа рекомендованных товаров
   * @example "Товары, пользующиеся наибольшим спросом у покупателей"
   */
  description?: string;
  icon?: MediaGroupIcon;
}

export interface FeedbackSubject {
  id: string
  /**
   * Код темы обращения
   * @example "myOrder"
   */
  code: string;
  /**
   * Тема обращения
   * @example "Узнать о моем заказе"
   */
  title: string;
}

/** Обращение в службу поддержки или обратная связь */
export interface Feedback {
  /**
   * Код темы обращения
   * @example "myOrder"
   */
  subjectId: string;
  /**
   * Id магазина, с которым связано обращение
   * @example "123"
   */
  storeId?: number;
  /** Имя того, кто оформляет обращение */
  name: string;
  /**
   * E-mail для связи по обращению
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * Телефон для связи по обращению
   * @example "79999999999"
   */
  phone?: string;
  /**
   * Текст обращения
   * @example "Можно ли добавить подарочную упаковку к заказу?"
   */
  message: string;
}

export interface Franchise {
  /** Имя того, кто оформляет обращение */
  name: string;
  /** Фамилия того, кто оформляет обращение */
  secondName: string;
  /**
   * E-mail для связи по обращению
   * @example "example@gmail.com"
   */
  email: string;
  /**
   * Текст обращения
   * @example "Можно ли добавить подарочную упаковку к заказу?"
   */
  message: string;
  /**
   * Телефон для связи по обращению
   * @example "79999999999"
   */
  phone?: string;
  /**
   * Признак принятия пользовательского соглашения
   * @example true
   */
  agreementConfirmed: boolean;
}

/** Общий объект для данных раздела меню, расширяемых на конкретном проекте */
export type SectionExtraData = object | null;

/** Общий объект для данных бренда, расширяемых на конкретном проекте */
export type BrandExtraData = object | null;

export interface Section {
  /**
   * ИД раздела меню
   * @example "ba6fc708-6bff-11ee-9d97-835063bd47b3"
   */
  id: string;
  /**
   * ИД родительской категории. В случае если равен null, то пункт первого уровня
   * @example null
   */
  parentId: string | null;
  /**
   * Код раздела меню на транслите (например: news, about)
   * @example "about"
   */
  code?: string;
  /**
   * Наименование раздела
   * @example "О магазине"
   */
  title: string;
  /**
   * Цвет текса пункта меню
   * @example "#ba1051"
   */
  titleColor?: string;
  /** Общий объект для данных раздела меню, расширяемых на конкретном проекте */
  extra?: SectionExtraData;
  /**
   * Код группы, к которой относится раздел
   * @example "right-column"
   */
  groupCode?: string;
  /**
   * Название группы, к которой относится раздел
   * @example "Правая колонка"
   */
  groupName?: string;
  /**
   * URL для перехода или раздел сайта
   * @example "/about/"
   */
  url?: string | null;
  icon?: MediaGroupIcon;
}

/** Общий объект для данных меню, расширяемых на конкретном проекте */
export type MenuExtraData = object | null;

/**
 * Код места размещения меню (например - top, bottom, bottom-horizontal и т.п.). В каждом проекте может быть свой набор кодов
 * @example "header"
 */
export enum MenuPlaceEnum {
  Header = "header",
  Footer = "footer",
  LEGAL_USER = 'menyu-dlya-yul',
  INDIVIDUAL_USER = 'menu-fl'
}

export interface MenuSection {
  /**
   * ИД раздела меню
   * @example "ba6fc708-6bff-11ee-9d97-835063bd47b3"
   */
  id: string;
  /**
   * ИД родительского раздела. В случае если равен null, то пункт первого уровня
   * @example null
   */
  parentId: string | null;
  /**
   * Код раздела меню на транслите (например - news, about)
   * @example "about"
   */
  code?: string;
  /**
   * Наименование раздела
   * @example "О магазине"
   */
  title: string;
  /**
   * Цвет текса пункта меню
   * @example "#ba1051"
   */
  titleColor?: string;
  /**
   * Код группы, к которой относится раздел
   * @example "right-column"
   */
  groupCode?: string;
  /**
   * Название группы, к которой относится раздел
   * @example "Правая колонка"
   */
  groupName?: string;
  /** Ссылка на раздел системы */
  url?: Link;
  icon?: MediaGroupIcon;
  nameplates?: Nameplate[]
}

export interface Menu {
  /** Код места размещения меню (например - top, bottom, bottom-horizontal и т.п.). В каждом проекте может быть свой набор кодов */
  place: MenuPlaceEnum;
  /**
   * Список разделов меню
   * @minItems 1
   */
  sections: MenuSection[];
}

export interface FaqGroup {
  /**
   * ИД группы
   * @example "70e1de6e-9341-11ee-82db-db08964fd8df"
   */
  id: string;
  /**
   * Код группы на транслите (например: news, about)
   * @example "about"
   */
  code?: string;
  /**
   * Наименование группы (plain text)
   * @example "Возврат товара"
   */
  title: string;
  /** Общий объект для данных групп вопросов, расширяемых на конкретном проекте */
  extra?: FaqGroupExtraData;
}

/** Общий объект для данных групп вопросов, расширяемых на конкретном проекте */
export type FaqGroupExtraData = object | null;

export interface Faq {
  /**
   * ИД вопрос-ответа
   * @example "40fdb216-9343-11ee-b832-9b69d0183e61"
   */
  id: string;
  /**
   * ИД группы вопросов
   * @example "70e1de6e-9341-11ee-82db-db08964fd8df"
   */
  groupId: string | null;
  /**
   * Код вопроса на транслите (например: news, about)
   * @example "about"
   */
  code?: string | null;
  /**
   * Текст вопроса (plain text)
   * @example "Как я могу уточнить актуальную стоимость нужного товара?"
   */
  question: string | null;
  /**
   * Текст ответа (plain text)
   * @example "В каталоге всегда актуальные цены."
   */
  answer: string | null;
  /**
   * Текст вопроса (html)
   * @example "Как я могу уточнить <b>актуальную</b> стоимость нужного товара?"
   */
  questionHtml: string | null;
  /**
   * Текст ответа (html)
   * @example "В каталоге <strong>всегда</strong> актуальные цены."
   */
  answerHtml: string | null;
  /** Общий объект для данных вопросов, расширяемых на конкретном проекте */
  extra?: FaqExtraData;
}

/** Общий объект для данных вопросов, расширяемых на конкретном проекте */
export type FaqExtraData = object | null;

/**
 * Код места размещения меню (например: top, bottom, bottom-horizontal и т.п.). В каждом проекте может быть свой набор кодов
 * @example "header"
 */
export enum MenuPlace {
  Header = "header",
  Footer = "footer",
}

export interface Language {
  /**
   * Код языка
   * @example "ru"
   */
  id: string;
  /**
   * Наименование языка
   * @example "Русский"
   */
  title: string;
  icon?: MediaGroupIcon;
}

export interface CollectionBase {
  /** Идентификатор коллекции */
  id: string;
  /**
   * Код коллекции (уникальный код коллекции - можно для URL)
   * @example "biggest-sale-of-summer"
   */
  code: string;
  /**
   * Описание коллекции
   * @example "Источник гармонии и энергии – малахит и символ стабильности и защиты – агат объединены в новую коллекцию бренда «Наяда». Ювелирная линейка представлена серебряными кольцами и серьгами с натуральными природными зелеными камнями в огранке «Кабошон». Современный дизайн в сочетании с красотой и мистической силой природы"
   */
  shortDescription?: string;
  /**
   * Название коллекции
   * @example "Итоги лета"
   */
  title: string;
  icon?: MediaGroupIcon;
  image?: MediaGroupImage;
}

export type CollectionShort = CollectionBase & {
  /**
   * Список товаров коллекции
   * @minItems 0
   */
  products?: {products: ProductShort[]};
  image?: MediaGroupImage;
  id: string;
};

export type CollectionDetail = CollectionBase & {
  /** Описание коллекции */
  fullDescription?: string;
  /** SEO-данные конкретной страницы */
  seoMetaData?: SeoMetaData;
};

export type CollectionTop = UtilRequiredKeys<CollectionBase, "image"> & {
  /**
   * Список товаров коллекции
   * @minItems 0
   */
  products?: {products: ProductShort[]};
  productCount?: number;
};

export interface ActionBase {
  /** Идентификатор акции */
  id: string;
  /**
   * Код акции (уникальный код акции - можно для URL)
   * @example "biggest-sale-of-summer"
   */
  code: string;
  /**
   * Краткое описание акции
   * @example "Бестселлеры уходящего сезона"
   */
  shortDescription?: string;
  /**
   * Название акции
   * @example "Итоги лета"
   */
  title: string;
  /**
   * @format date
   * @example "2023-06-01T00:00:00.000Z"
   */
  activeFrom?: string;
  /**
   * @format date
   * @example "2023-08-31T00:00:00.000Z"
   */
  activeTo?: string;
  /**
   * Шильдики товара
   * @minItems 0
   */
  nameplates?: Nameplate[];
  icon?: MediaGroupIcon;
  image?: MediaGroupImage;
}

export type ActionShort = ActionBase & {
  /**
   * Список товаров, участвующих в акции
   * @minItems 0
   */
  products?: ProductShort[];
};

export type ActionDetail = ActionBase & {
  /** Описание акции */
  fullDescription?: string;
  /** Условия акции */
  terms?: string;
  /**
   * Список товаров, участвующих в акции
   * @minItems 0
   */
  products?: ProductShort[];
  /** SEO-данные конкретной страницы */
  seoMetaData?: SeoMetaData;
};

export interface ProductCountableGroup {
  /** @example "Новинки недели" */
  title?: string;
  /** Ссылка на раздел системы */
  link?: Link;
  products: ProductBase[];
  /** Данные по навигации */
  page: PageInfo;
}

export type ActionTop = UtilRequiredKeys<ActionBase, "image"> & {
  products?: ProductCountableGroup;
};

export interface ServiceBase {
  /** Идентификатор услуги */
  id: string;
  /**
   * Код услуги (уникальный код акции - можно для URL)
   * @example "biggest-sale-of-summer"
   */
  code: string;
  /**
   * Краткое описание услуги
   * @example "Резка дерева"
   */
  shortDescription?: string;
  /**
   * Название услуги
   * @example "Резка дерева"
   */
  title: string;
  image?: MediaGroupImage;
  icon?: MediaGroupIcon;
}

export type ServiceShort = ServiceBase;

export type ServiceDetail = ServiceBase & {
  /** Описание услуги */
  fullDescription?: string;
  /** SEO-данные конкретной страницы */
  seoMetaData?: SeoMetaData;
};

export type ServiceTop = ServiceShort & {
  /**
   * Список товаров бренда
   * @minItems 0
   */
  products?: ProductShort[];
  productCount?: number;
};


/** Данные о характеристиках корзины и заказа */
export interface MeasureTotal {
  /**
   * Вес товаров в заказе (в килограммах)
   * @format float
   * @example 1.5
   */
  weight?: number;
  /**
   * Объем товаров в заказе (в кубометрах)
   * @format float
   * @example 0.08
   */
  volume?: number;
}

/** Итоговые данные о корзине */
export interface BasketTotal {
  /** Итоговые стоимости корзины или заказа */
  price: TotalPrice;
  /** Информация о сроках и ценах доставки конкретных товаров */
  deliveryInfo?: DeliveryInfo[];
  /** Данные о характеристиках корзины и заказа */
  measure?: MeasureTotal;
  promoCode?: string
}

/** Итоговые стоимости корзины или заказа */
export interface TotalPrice {
  /**
   * Стоимость товаров заказа без учета скидок (значение умноженное на 100 - в копейках)
   * @example 119900
   */
  cost: number;
  /**
   * Стоимость доставки заказа (значение умноженное на 100 - в копейках)
   * @example 119900
   */
  deliveryCost?: number;
  /**
   * Размер скидок (значение умноженное на 100 - в копейках)
   * @example 11990
   */
  discount: number;
  /**
   * Итоговая стоимость заказа к оплате (значение умноженное на 100 - в копейках)
   * @example 119900
   */
  totalCost: number;
  /**
   * Валюта стоимости (RUB)
   * @example "RUB"
   */
  currency?: "RUB";
}

/**
 * @export
 * @namespace OrderPaymentStatusEnum
 */
export namespace OrderPaymentStatusEnum {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    NOTPAID = <any> 'NOT_PAID',
    PAID = <any> 'PAID',
    PARTIALLYPAID = <any> 'PARTIALLY_PAID'
  }
}

/**
 * Статус оплаты заказа
 * @export
 * @interface OrderPaymentStatusEnum
 */
export interface OrderPaymentStatusEnum {
  /**
   * Оплаченная сумма (в копейках)
   * @type {number}
   * @memberof OrderPaymentStatusEnum
   */
  paidSum?: number;
  /**
   * Статус оплаты
   * @type {string}
   * @memberof OrderPaymentStatusEnum
   */
  status?: OrderPaymentStatusEnum.StatusEnum;
}

/** Базовая информация о заказе */
export interface OrderBase {
  /** Идентификатор заказа */
  id: string;
  /** Статус заказа */
  orderStatus: OrderStatus;
    /** Статус оплаты */
  paidStatus: OrderPaymentStatusEnum;
  /**
   * @format date-time
   * @example "2023-07-21T17:32:28Z"
   */
  date: string;
  /**
   * Количество товаров в заказе
   * @example 3
   */
  quantity?: number;
  /** Итоговые стоимости корзины или заказа */
  total: TotalPrice;
  deliveryMethod?: DeliveryMethodShort;
  orderNotice?: {
    /**
     * Заголовок сообщения
     * @example "Ожидает получения до 24.04.2023"
     */
    title: string;
    /**
     * Текст сообщения
     * @example "По истечении срока хранения заказ будет расформирован, а деньги возвращены на карту"
     */
    message: string;
  } | null;
  paymentMethod: PaymentMethodBase;
  deliveryData?: OrderCourierDeliveryData | OrderPickupDeliveryData;
}

export type OrderShort = Omit<UtilRequiredKeys<OrderBase, "quantity">, 'paymentMethod'> & {
  paymentMethod?: PaymentMethodBase;
};

export type OrderDetail = UtilRequiredKeys<OrderBase, "deliveryMethod"> & {
  paymentMethod: PaymentMethodShort;
  /** Комментарий покупателя к заказу */
  comment?: string;
  customer?: OrderCustomer;
  /**
   * Список товаров в заказе
   * @minItems 1
   */
  products: OrderItem[];
  deliveryData?: OrderCourierDeliveryData | OrderPickupDeliveryData | ProfileOrderPickupDeliveryData;
};

/** Товар в заказе */
export interface OrderItem {
  /**
   * Количество единиц товара в заказе
   * @example 5
   */
  quantity: number;
  product: ProductShort;
}

/** Товар в корзине */
export interface BasketItem {
  /**
   * Количество единиц товара в заказе
   * @example 5
   */
  quantity: number;
  /**
   * Товар в корзине выбран для покупки
   * @example true
   */
  isSelected?: boolean;
  /**
   * Признак того, что товар добавлен в корзину в качестве подарка
   * @example true
   */
  isGift?: boolean;
  product: ProductBase;
}

/**
 * Статус заказа
 * @example "CREATED"
 */
export enum OrderStatus {
  CREATED = "CREATED",
  PAYED = "PAYED",
  IN_ASSEMBLY = "IN_ASSEMBLY",
  IN_DELIVERY = "IN_DELIVERY",
  READY_FOR_PICKUP = "READY_FOR_PICKUP",
  COMPLETED = "COMPLETED",
  ARCHIVE = "ARCHIVE",
  CANCELED = "CANCELED",
}

/** Способ доставки */
export interface DeliveryMethodBase {
  /**
   * Идентификатор способа доставки
   * @example "1723c6f6-81eb-11ee-af6e-d311efdae2f7"
   */
  id: string;
  /**
   * Код способа доставки
   * @example "cash"
   */
  code: string;
  /** Тип доставки */
  type: DeliveryType;
  /**
   * Наименование способа доставки
   * @example "Самовывоз"
   */
  title: string;
  icon?: MediaGroupIcon;
  /**
   * Выбранный способ получения заказа (по умолчанию или пользователем).
   * @example false
   */
  isApplied?: boolean;
  /**
   * Возможность использования службы доставки
   * @example true
   */
  isAvailable?: boolean | null;
}

export type DeliveryMethodShort = DeliveryMethodBase;

/** Способ оплаты */
export interface PaymentMethodBase {
  /**
   * Идентификатор способа оплаты
   * @example "38ee28f4-81ef-11ee-8a48-b70bb8afb523"
   */
  id: string;
  /**
   * Код способа оплаты
   * @example "cash"
   */
  code: TPaymentMethodCode;
  /**
   * Название способа оплаты
   * @example "Наличные"
   */
  title: string;
  icon?: MediaGroupIcon;
  /**
   * Выбранный способ оплаты заказа (по умолчанию или пользователем).
   * @example false
   */
  isApplied?: boolean;
  /**
   * Возможность использования способа оплаты
   * @example true
   */
  available?: boolean;
}

export type TPaymentMethodCode = "card-or-sbp" | "on-reciept"

export type PaymentMethodShort = PaymentMethodBase;

/** SEO-данные конкретной страницы */
export type SeoMetaData = {
  /**
   * Заголовок страницы
   * @example "Вентилятор BORGE Подкровельный Viotto черепаха для ГЧ"
   */
  title: string;
  /**
   * Ключевые слова страницы
   * @example "Вентилляция, Кровля, BORGE"
   */
  keywords: string;
  /**
   * Элемент кровельной вентиляции, обеспечивающий дополнительный приток возуха в подкровельное пространство
   * @example "Русский"
   */
  description: string;
  /**
   * SEO-текст
   * @example "Элемент кровельной вентиляции, обеспечивающий дополнительный приток возуха в подкровельное пространство, Устраняет конденсат, защищает деревянные элементы от излишей влажности, а следовательно от образования грибка и плесени. Предназначен для монтажа на металлочерепицу профиль Монтеррей, Супер Монтеррей, Макси"
   */
  text?: string;
} | null;

/** Общий объект для данных категорий, расширяемых на конкретном проекте */
export type CategoryExtraData = object | null;

/** Объект доступного свойства варианта товара (торгового предложения) */
export interface ProductVariantOption {
  /**
   * Идентификатор свойства
   * @example "7189bf14-906e-72a7-9fcf-dc3d5a23267c"
   */
  id: string;
  /**
   * Код свойства
   * @example "color"
   */
  code: string;
  /**
   * Наименование свойства
   * @example "Цвет"
   */
  title: string;
  icon?: MediaGroupIcon;
}

/** Объект значения свойства варианта товара (торгового предложения) */
export interface ProductVariantOptionValue {
  /**
   * Код свойства
   * @example "color"
   */
  code: string;
  /**
   * Значение свойства
   * @example "Красный"
   */
  value: string;
}

/** Объект пункта самовывоза */
export interface PickupPoint {
  /**
   * Идентификатор пункта
   * @example "01890b73-fd8e-736a-894a-49836ef69b65"
   */
  id: string;
  /**
   * Код пункта
   * @example "paterochka"
   */
  code?: string;
  /**
   * Наименование пункта
   * @example "Магазин пятерочка в г. Астана"
   */
  title: string;
  /**
   * Широта
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "54.695829999995"
   */
  latitude: string;
  /**
   * Долгота
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "20.525669"
   */
  longitude: string;
  /**
   * Адрес доставки
   * @example "г. Астана, ул. Ленина, 1"
   */
  address?: string;
  /**
   * График работы магазина (дни и часы)
   * @minItems 0
   */
  schedule?: WorkingHours[];
  /**
   * Текст сообщения
   * @example "По истечении срока хранения заказ будет расформирован, а деньги возвращены на карту"
   */
  message?: string;
  /**
   * Телефон
   * @example "+7 777 777 77 77"
   */
  phone?: string;
  icon?: MediaGroupIcon;
  /**
   * Временные слоты на конкретные даты для самовывоза
   * @minItems 0
   */
  timeslots?: DateSlots[];
}

/** Временные слоты в конкретный день */
export interface DateSlots {
  /**
   * Дата самовывоза
   * @format date
   * @example "2024-05-15"
   */
  date: string;
  /**
   * Временные слоты
   * @minItems 0
   */
  slots: TimeSlot[];
}

/** Временной слот */
export interface TimeSlot {
  /**
   * Время начала слота
   * @format time
   * @example "8:00"
   */
  from: string;
  /**
   * Время окончания слота
   * @format time
   * @example "12:00"
   */
  to: string;
}

/** Пол */
export enum Gender {
  Male = "male",
  Female = "female",
}

/** Идентификатор адреса доставки */
export interface OrderAddressId {
  /**
   * Идентификатор адреса доставки
   * @example "01890b73-d30a-7296-9d62-f9e3f313224b"
   */
  id: string;
}

/**
 * Адрес доставки
 * @example {"address":"г. Астана, ул. Ленина, 1","latitude":"54.695829999995","longitude":"20.525669","entrance":1,"apartment":"1","zip":"123456","floor":4,"doorcode":"#2332#","isPrivateHouse":false}
 */
export type OrderAddress = UtilRequiredKeys<
  DeliveryAddressBase,
  "address" | "latitude" | "longitude" | "isPrivateHouse" | "entrance" | "apartment" | "zip"
>;

/** Подписки */
export interface Subscriptions {
  /**
   * Подписки на email рассылку
   * @example true
   */
  isEmailSubscribe?: boolean;
  /** Подписка на рассылку уведомлений по sms */
  isSmsSubscribe?: boolean;
  /** Разрешение на звонки колл-центра */
  isCallSubscribe?: boolean;
}

export interface DeliveryInfo {
  deliveryMethod: DeliveryMethodShort;
  /**
   * Минимальная стоимость доставки заказа данным методом (в копейках)
   * @example 25000
   */
  deliveryMinPrice: number;
  /**
   * Минимальная дата доставки заказа данным методом
   * @format date-time
   */
  deliveryDate?: string;
  /**
   * Интервалы доставки
   * @example "3-6 дней"
   */
  deliveryInterval?: string;
}

/** Ссылка на раздел системы */
export interface Link {
  /** Тип ссылки (на категорию, на товар, и т.д. либо прямой URL) */
  type?:
      | "category"
      | "product"
      | "brand"
      | "action"
      | "service"
      | "article"
      | "news"
      | "collection"
      | "content-page"
      | "store"
      | "url";
  /** Значение ссылки (либо ИД соответствующей сущности, либо прямой URL) */
  value?: string;
}

/** Обычное для адаптива (несколько вариантов изображения для разных разрешений) */
export interface MediaImageAdaptive {
  /** Тип медиадокумента */
  type: MediaTypeEnum;
  /**
   * Альтернативный текст (отображающийся в случае невозможности отображения медиадокумента)
   * @example "Здесь должно быть изображение, но что-то пошло не так"
   */
  alternative?: string | null;
  /**
   * Наименование изображение
   * @example "Наименование изображение"
   */
  title: string;
  /**
   * URL к медиадокументу
   * @example "https://example.org/image-s.jpg"
   */
  urlS: string;
  /**
   * URL к медиадокументу
   * @example "https://example.org/image-m.jpg"
   */
  urlM: string;
  /**
   * URL к медиадокументу
   * @example "https://example.org/image-l.jpg"
   */
  urlL: string;
  /**
   * URL к медиадокументу
   * @example "https://example.org/image-mobile.jpg"
   */
  urlMobile: string;
}

/** Обычное изображение */
export interface MediaImage {
  /** Тип медиадокумента */
  type: MediaTypeEnum;
  /** Наименование документа */
  title: string;
  /** URL к медиадокументу */
  url: string;
  /** Альтернативный текст (отображающийся в случае невозможности отображения медиадокумента) */
  alternative?: string | null;
}

/** Иконка в формате SVG */
export interface MediaIcon {
  /** Тип медиадокумента */
  type: MediaTypeEnum;
  /** Наименование документа */
  title: string;
  /** URL к медиадокументу */
  url: string;
}

/** Видео на youtube */
export interface MediaYoutube {
  /** Тип медиадокумента */
  type: MediaTypeEnum;
  /** Наименование документа */
  title: string;
  /** URL к медиадокументу */
  url: string;
  /** Обычное изображение */
  preview?: MediaImage;
}

/** Видео по прямой ссылке */
export interface MediaVideo {
  /** Тип медиадокумента */
  type: MediaTypeEnum;
  /** Наименование документа */
  title: string;
  /** URL к медиадокументу */
  url: string;
  /** Обычное изображение */
  preview?: MediaImage;
}

/** Файл */
export interface MediaFile {
  /** Тип медиадокумента */
  type: MediaTypeEnum;
  /** Наименование документа */
  title: string;
  /** URL к медиадокументу */
  url: string;
  /**
   * Размер файла в байтах
   * @example 1048576
   */
  size: number;
  /**
   * MIME тип документа
   * @default "application/octet-stream"
   * @example "application/pdf"
   */
  mimetype:
    | "application/msword"
    | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    | "application/pdf"
    | "application/vnd.ms-powerpoint"
    | "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    | "application/vnd.ms-excel"
    | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    | "application/zip"
    | "application/octet-stream";
  /** Обычное изображение */
  preview?: MediaImage;
}

/** Тип медиадокумента */
export enum MediaTypeEnum {
  Icon = "icon",
  Image = "image",
  ImageAdaptive = "image-adaptive",
  Video = "video",
  File = "file",
  Rutube = "rutube",
  Youtube = "youtube",
}

/** Символьные коды проморядов товаров. На кажом проекте может быть свой набор кодов */
export enum ProductGroupEnum {
  First = "first",
  Second = "second",
  Viewed = "viewed",
  Recommended = "recommended",
  Newest = "newest",
  Bestseller = "bestseller",
  Similar = "similar"
}

export type MediaGroupIcon = MediaImage | MediaIcon | (MediaImage & MediaIcon);

export type MediaGroupVisual =
  | MediaImageAdaptive
  | MediaImage
  | MediaYoutube
  | MediaVideo
  | MediaIcon
  | (MediaImageAdaptive & MediaImage & MediaYoutube & MediaVideo & MediaIcon);

export type MediaGroupImage = MediaImageAdaptive | MediaImage | (MediaImageAdaptive & MediaImage);

export interface DaData {
  value: string;
  data: {
    city: string | null;
    street_with_type: string | null;
    house: string | null;
    flat: string | null;
    geo_lat: string | null;
    geo_lon: string | null;
    postal_code: string | null;
    kpp: string
    inn: string
    name: {
      full: string
    }
    address: {
      value: string
    }
    management: {
      name: string
    } | null
  }
}